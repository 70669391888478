import Typography from "@mui/material/Typography";

function LightHeading(props) {
  return (
    <Typography
      variant="body2"
      fontWeight="300"
      color={props.color}
      sx={{ opacity: 0.6 }}
    >
      {props.children}
    </Typography>
  );
}

function Body1(props) {
  return (
    <Typography variant="body1" fontWeight="300" color={props.color}>
      {props.children}
    </Typography>
  );
}

export { LightHeading, Body1 };
