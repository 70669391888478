import React, { useEffect, useState } from "react";
import JobCustomController from "../../controller/JobCustomController";
import {
  DropdownMenu,
  DropdownItem,
  DropdownHeader,
  Dropdown,
  Input,
} from "semantic-ui-react";

import { CommonColor } from "../../Utils/color";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";
export default function JobTableFilter(props) {
  const { getFilterListByName, onClickOnFilter } = JobCustomController();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedData, setSelectedData] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    getSearchResult().map((option, index) => {
      let name = props.directName ? option : option.name;
      if (index == 0) {
        setSelectedData(name);
      }
    });
  }, []);

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  // Update the search query as the user types
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Prevent dropdown from closing when clicking on the input
  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const inputStyle = {
    width: "100%", // Ensure the input takes 100% of the container width
    margin: "30px 10px 10px 10px", // Add some vertical spacing
  };

  function getSearchResult() {
    if (searchQuery) {
      if (searchQuery) {
        return getFilterListByName(props.name, searchQuery)?.list.filter(
          (item) => item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    }
    return getFilterListByName(props.name, searchQuery)?.list;
  }

  function getFiltername(selected_name) {
    setSelectedData(selected_name);
  }
  var isActiveFilter = getFilterListByName(props.name, "").is_active_filter;
  return (
    <div style={{ zIndex: 1, Position: "absolute" }}>
      <Dropdown
        className={isActiveFilter ? "active" : ""}
        // style={{
        //   color: getFilterListByName(props.name, "").is_active_filter
        //     ? CommonColor.secondary_color
        //     : "white",
        //   overflow: "visible",
        // }}
        icon={false}
        text={
          <p>
            {props.name}{" "}
            <Icon
              style={{
                marginLeft: 5,
                color: isActiveFilter
                  ? CommonColor.secondary_color
                  : CommonColor.white,
              }}
              icon={CommonIcon.filter}
            />
          </p>
        }
        // icon="filter"
        direction={
          getFilterListByName(props.name, searchQuery)?.list.length > 7
            ? "left"
            : "right"
        }
      >
        <DropdownMenu style={{ height: "auto", zIndex: 1000 }}>
          <div
            onClick={handleInputClick}
            style={{
              display:
                getFilterListByName(props.name, searchQuery)?.list.length > 7
                  ? "flex"
                  : "none",
              width: "100%",
            }}
          >
            <Input
              icon="search"
              iconPosition="right"
              value={searchQuery}
              onChange={handleInputChange}
              placeholder={`Search ${props.name}...`}
              className="search"
              style={inputStyle}
            />
          </div>
          <DropdownHeader
            icon="tags"
            content={selectedData}
            style={{ color: "green", fontSize: 12 }}
          />

          <DropdownMenu scrolling>
            {getSearchResult().map((option) => {
              let name = props.directName ? option : option.name;
              return (
                <DropdownItem
                  key={name}
                  value={name}
                  text={name}
                  onClick={() => {
                    onClickOnFilter(props.name, option);
                    getFiltername(name);
                    handleClose();
                  }}
                  style={{
                    color: getFilterListByName(props.name, option).is_active
                      ? CommonColor.secondary_color
                      : "black",
                    fontSize: 12,
                  }}
                />
              );
            })}
          </DropdownMenu>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
