import React from "react";
import VerticalMenu from "../../CommonComponet/VerticalMenu";
import { UPDATE_CLIENT, VIEW_CLIENT } from "../../../Utils/enum";
import Container from "../../CommonComponet/Container";
import {
  CustomTableContainer,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
  Table,
} from "../../../style/table.styled";
import { CLIENT_TH } from "../../../Utils/constant";
import { CommonString } from "../../../Utils/CommonString";
import { getAccessForClient } from "../../../Utils/utilitiFunction";
import { useSelector } from "react-redux";
import { SwitchWithTitle } from "../../../style/common.style";
import CustomSwitch from "../../CommonComponet/CustomSwitch";
import { confirmAlert } from "react-confirm-alert";
import ConfirmationBox from "../../CommonComponet/Client/ConfirmationBox";
import ClientController from "../../../controller/ClientController";
import { UPDATE_CLIENT_SUCCESS } from "../../../Utils/alertMessage";
import { useAlert } from "react-alert";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";

export default function ClientTable(props) {
  const alert = useAlert();
  const clientController = new ClientController();
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  function getPartnerName(data) {
    if (data === undefined) return "";
    let total = "";
    const tempNewData = JSON.parse(data.partner_id);
    for (let i = 0; i < tempNewData.length; i++) {
      const seprator = i === 0 ? "" : ", ";
      total += seprator + tempNewData[i].name;
    }
    return total;
  }
  function onChangeActiveClient(checked, data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationBox
            name={data.name}
            onClose={onClose}
            active={checked}
            onUpdate={() => {
              onUpdate(checked ? 0 : 1, data);
              onClose();
            }}
          />
        );
      },
    });
  }
  async function onUpdate(checked, data) {
    var sendData = {
      ...data,
      _is_active: checked,
    };
    var res = await clientController.updateClient(sendData);
    alert.show(UPDATE_CLIENT_SUCCESS, { type: CommonString.success });
    props.onRefresh();
  }
  return (
    <CustomTableContainer>
      <Table>
        <TableTbody>
          <TableRow>
            {CLIENT_TH.map((singleTH, index) => (
              <TableTh align={singleTH.align} key={index}>
                {singleTH.name}
              </TableTh>
            ))}
          </TableRow>
          {props.clientlList.length === 0 ? (
            <></>
          ) : (
            props.clientlList.map((data, index) => {
              return (
                <TableRow
                  key={index}
                  onDoubleClick={() => props.onViewModal(VIEW_CLIENT, data)}
                  onClick={() =>
                    window.innerWidth < 500
                      ? props.onViewModal(VIEW_CLIENT, data)
                      : null
                  }
                >
                  <TableTd style={{ paddingLeft: 10 }}>{index + 1}</TableTd>
                  <TableTd initial={CommonString.true}>{data.name}</TableTd>
                  <TableTd>
                    <span style={{ display: "flex" }}>
                      <CustomizedTooltip
                        name={getPartnerName(data)}
                        title={getPartnerName(data)}
                      />
                    </span>
                  </TableTd>
                  <TableTd style={{ zIndex: 2 }} isCenter={true}>
                    {/* <SwitchWithTitle> */}
                    <div style={{ position: "relative", zIndex: 0 }}>
                      <CustomSwitch
                        defaultChecked={data._is_active === 0}
                        defaultValue={data._is_active === 0}
                        onChange={(e) =>
                          onChangeActiveClient(e.target.checked, data)
                        }
                      />
                    </div>
                    {/* </SwitchWithTitle> */}
                    {/* <JobFirstColumn
                      style={{
                        backgroundColor:
                          jobStatusColor[data._is_active].backgroundColor,
                        color: jobStatusColor[data._is_active].color,
                      }}
                    >
                      <Icon icon="octicon:dot-fill-16" />{" "}
                      {data._is_active === 0 ? "Active" : "In Active"}
                    </JobFirstColumn> */}
                  </TableTd>
                  <TableTd>
                    <VerticalMenu
                      data={getAccessForClient(access_data_by_user_id)}
                      onView={() => props.onViewModal(VIEW_CLIENT, data)}
                      onEdit={() => props.onViewModal(UPDATE_CLIENT, data)}
                      onDelete={() => props.onDelete(data)}
                    />
                  </TableTd>
                </TableRow>
              );
            })
          )}
        </TableTbody>
      </Table>
      <Container
        isLoading={props.isLoading === true}
        isVisible={props.clientlList.length === 0}
      />
    </CustomTableContainer>
  );
}
