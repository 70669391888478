import React from "react";
import { Body1, LightHeading } from "./TileStyle";
import { Grid } from "@mui/material";
import moment from "moment";
import { getCapitalizeText } from "../../Utils/utilitiFunction";

export default function ProfessionalDetails(props) {
  const { data } = props;
  return data.length == 0 ? (
    <></>
  ) : (
    <Grid container>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Email</LightHeading>
        <Body1>{data[0].personal_email}</Body1>
      </Grid>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Anniversary Date</LightHeading>
        <Body1>{ moment(data[0].anniversary_date).format('DD MMM YYYY')}</Body1>
      </Grid>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Emergency Contact</LightHeading>
        <Body1>{data[0].emergency_contact}</Body1>
      </Grid>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Experience</LightHeading>
        <Body1>{data[0].experience}</Body1>
      </Grid> 
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Google Fit Email</LightHeading>
        <Body1>{data[0].google_fit_email}</Body1>
      </Grid> 
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Joining Date</LightHeading>
        <Body1>{ moment(data[0].joining_date).format('DD MMM YYYY')}</Body1>

      </Grid> 
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Marital Status</LightHeading>
        <Body1>{data[0].marital_status}</Body1>
      </Grid>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Nationality</LightHeading>
        <Body1>{data[0].nationality}</Body1>
      </Grid>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Aadhar Card Number</LightHeading>
        <Body1>{data[0].aadhar_card_number}</Body1>
      </Grid>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Pan Number</LightHeading>
        <Body1>{data[0].pan_number}</Body1>
      </Grid>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Passport Number</LightHeading>
        <Body1>{data[0].passport_number}</Body1>
      </Grid>
      <Grid item md={3}  paddingBottom={2}>
        <LightHeading>Phone Number</LightHeading>
        <Body1>{data[0].phone_number}</Body1>
      </Grid>
      <Grid item md={6}>
        <LightHeading>Current Address</LightHeading>
        <Body1>{getCapitalizeText (data[0].current_address)}</Body1>
      </Grid>
      <Grid item md={6}>
        <LightHeading>Permanent Address</LightHeading>
        <Body1>{getCapitalizeText (data[0].permanent_address)}</Body1>
      </Grid>
    </Grid>
  );
}
