import { TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "semantic-ui-react";

import {
  CustomTableContainer,
  DashboardTableContainer,
  JobFirstColumn,
  Table,
  TableName,
  TableTd,
  TableTh,
} from "../../style/table.styled";
import {
  getClientName,
  jobStatusColor,
  onCheckLevelAccessibility,
} from "../../Utils/utilitiFunction";
import { VIEW_JOBS } from "../../Utils/enum";
import { checkNullString, getDate } from "../../Utils/validation";
import { JOB_TH_FOR_DASHBOARD } from "../../Utils/constant";
import { CommonColor } from "../../Utils/color";
import JobController from "../../controller/JobController";
import {
  changeJobDataForDashboard,
  changeSelectedData,
} from "../../feature/store/JobReduxStore";
import CommonBaseModal from "../Modal/CommonBaseModal";
import {
  changeButtonName,
  changeIsOpenModal,
} from "../../feature/store/ApplicantStore";
import JobViewDetails from "../ViewDetails/JobViewDetails";
import { MultipleSignContainer } from "../../style/job.style";

export default function JobList() {
  const jobController = new JobController();
  const dispatch = useDispatch();
  const job_data_for_dashboard = useSelector(
    (state) => state.JobReduxStore.job_data_for_dashboard
  );
  const [selectedData, setselectedData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    // var user = localStorage.getItem("USER");
    // const sendData = {
    //   recruiter_id: JSON.parse(user).user._id,
    // };
    // console.log(sendData);
    // return;
    // try {
    if (onCheckLevelAccessibility()) {
      return;
    }
    var user = localStorage.getItem("USER");
    const sendData = {
      recruiter_id: JSON.parse(user).user._id,
    };
    var res = await jobController.getAllJobByStatus(sendData);
    dispatch(
      changeJobDataForDashboard([...res?.data?.data, ...res?.data?.multiple])
    );
    // } catch (error) {
    //   console.log(error);
    // }
  }
  function getVisibility() {
    if (
      job_data_for_dashboard === undefined ||
      job_data_for_dashboard.length === 0
    ) {
      return true;
    }
    return false;
  }
  function onViewModal(modalSt, data) {
    dispatch(changeIsOpenModal(true));
    dispatch(changeButtonName(modalSt));
    dispatch(changeSelectedData(data));
    setselectedData(data);
  }
  function getMultipleLebel(data) {
    if (data._is_multiple) {
      // const names = ["Ankur", "Kartik", "Kartik"];
      // const names= data.Recruiter_list.map((item) => {
      //   if (user_data._id !== item.recruiter_id) {
      //   return item.recruiter.name.split(" ")[0];

      //   }
      // }).join(", ");
      return (
        <Label as="a" tag size="mini" color="green">
          Multiple
        </Label>
      );
    }
  }

  return getVisibility() ? (
    <></>
  ) : (
    <DashboardTableContainer>
      <CustomTableContainer style={{ marginTop: 10 }} height={"auto"}>
        <CommonBaseModal children={<JobViewDetails data={selectedData} />} />
        <TableName>Position</TableName>
        <Table style={{ background: CommonColor.white, border: "none" }}>
          <TableRow>
            {JOB_TH_FOR_DASHBOARD.map((singleTH, index) => (
              <TableTh align={singleTH.align} key={index}>
                {singleTH.name}
              </TableTh>
            ))}
          </TableRow>
          {job_data_for_dashboard.map((data, index) => (
            <TableRow
              onDoubleClick={() => onViewModal(VIEW_JOBS, data)}
              onClick={() =>
                window.innerWidth < 500 ? onViewModal(VIEW_JOBS, data) : null
              }
              key={index + 1}
            >
              <TableTd isVisible={true}>
                <JobFirstColumn
                  style={{
                    color: jobStatusColor[data?.status].color,
                    background: jobStatusColor[data?.status].backgroundColor,
                  }}
                >
                  {data?.status}
                </JobFirstColumn>
              </TableTd>
              <TableTd>{getDate(data)}</TableTd>
              <TableTd>{data?.job_title}</TableTd>
              <TableTd>{checkNullString(data?.client_position_id)}</TableTd>
              <TableTd>{getClientName(data?.client_name)}</TableTd>
              <TableTd isCenter={true}>
                {checkNullString(data?.max_submission)}
              </TableTd>
              <TableTd isCenter={true}>
                {checkNullString(data?.no_of_submission)}
              </TableTd>
              <TableTd textAlignLeft={true}>
                <MultipleSignContainer>
                  {getClientName(data?.partner_name)}
                  {getMultipleLebel(data)}
                </MultipleSignContainer>
              </TableTd>
            </TableRow>
          ))}
        </Table>
      </CustomTableContainer>
    </DashboardTableContainer>
  );
}
