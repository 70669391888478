import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ApplicantData from "./view_component/ApplicantData";
import {
  changeSelectedRecruiterId,
  changeTempJobList,
} from "../../feature/store/JobReduxStore";
import { ApplicantViewContainer } from "../../style/common.style";

import { CommonStringForLocalStorage } from "../../Utils/CommonString";
import { CommonColor } from "../../Utils/color";
import BaseModal from "../Modal/BaseModal";
import { VIEW_SUBMISSION } from "../../Utils/enum";
import SubmissionViewDetails from "./SubmissionViewDetails";

export default function ApplicantDataDetails(props) {
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.counter.jobData);
  const applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  const [progress, setProgress] = useState(0);
  const [isopenModal, setisOpenModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    var user = JSON.parse(
      localStorage.getItem(CommonStringForLocalStorage.user)
    );
    dispatch(changeSelectedRecruiterId(user.user._id));
    dispatch(changeTempJobList(jobData));
  }
  function onViewModal(modalSt, data) {
    setisOpenModal(!isopenModal);
    setselectedData(data);
  }

  return (
    <>
      <BaseModal
        isBig={true}
        open={isopenModal}
        formTitle={VIEW_SUBMISSION}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        <SubmissionViewDetails data={selectedData} />
      </BaseModal>
      <ApplicantViewContainer>
        <ApplicantData
          onViewModal={onViewModal}
          isLockView={false}
          data={applicant}
        />
      </ApplicantViewContainer>
    </>
  );
}
