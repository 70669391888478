import React from "react";
import { TableRow, TableTd } from "../../../style/table.styled";

export default function ViewDetailTileForApplicant(props) {
  const { value, name } = props;
  const isValidate =
    value === "" ||
    value === "$" ||
    value === "--" ||
    value === null ||
    value === undefined;

  return isValidate ? (
    <></>
  ) : (
    <TableRow style={{ margin: 5 }}>
      <TableTd textAlignLeft={true}>
        <b> {name}</b>
      </TableTd>
      <TableTd textAlignLeft={true}>{value}</TableTd>
    </TableRow>
  );
}
