import { useDispatch, useSelector } from "react-redux";
import ApplicantProvider from "../services/provider/ApplicantProvider";
import { onCheckLevelAccessibility } from "../Utils/utilitiFunction";
import JobController from "./JobController";
import {
  changeJobListForApplicant,
  changeLoadingJob,
} from "../feature/store/ApplicantStore";

export default class ApplicantContoller {
  constructor() {
    this.applicantProvider = new ApplicantProvider();
    this.jobController = new JobController();
    this.dispatch = new useDispatch();
    this.selected_recruiter_id = new useSelector(
      (state) => state.JobReduxStore.selected_recruiter_id
    );
    this.jobPagenumber = new useSelector(
      (state) => state.counter.jobPagenumber
    );
  }
  getJobDataList = async (page, e) => {
    // console.log(e);
    this.dispatch(changeLoadingJob(true));
    if (onCheckLevelAccessibility() || e === "123") {
      var data = {
        status: "OPEN",
        order: "",
        recruiter_id: "123",
        partner_id: "123",
        client_id: "123",
      };
      var res_1 = await this.jobController.getJobsByStatus(data, page);
      this.dispatch(changeJobListForApplicant(res_1));
      this.dispatch(changeLoadingJob(false));
      return res_1;
    }
    // const sendData = {
    //   recruiter_id: e,
    // };
    // var res = await this.jobController.getAllJobByStatus(sendData);
    // this.dispatch(changeJobListForApplicant(res.data.data));
    this.dispatch(changeLoadingJob(false));
    // return res.data.data;
  };
  getApplicants = async (page) => {
    var res = await this.applicantProvider.getApplicantList(page);
    return res.data.data;
  };
  searchApplicant = async (data, page) => {
    var res = await this.applicantProvider.searchApplicant(data, page);
    return res.data.data;
  };
  deleteApplicant = async (data) => {
    var res = await this.applicantProvider.deleteApplicant(data);
    return res;
  };
  addApplicant = async (data) => {
    var res = await this.applicantProvider.addNewApplicant(data);
    return res;
  };
  updateApplicant = async (data) => {
    var res = await this.applicantProvider.updateApplicant(data);
    return res;
  };
  checkApplicantExist = async (data) => {
    var res = await this.applicantProvider.checkApplicantExist(data);
    return res.data;
  };
  addComment = async (data) => {
    var res = await this.applicantProvider.addComment(data);
    return res.data;
  };
  updateComment = async (data) => {
    var res = await this.applicantProvider.updateComment(data);
    return res.data;
  };
  jobListForLockApplicant = async (data) => {
    var res = await this.applicantProvider.jobListForLockApplicant(data);
    return res.data.data;
  };
}
