/* eslint-disable eqeqeq */
import moment from "moment";
import { CommonString } from "./CommonString";
import {
  BLANK_TEXT_VALIDATION,
  DATE_FORMATE,
  RESUME_BASEURL,
} from "./constant";

function checkString(text) {
  if (text.length == 0) {
    return BLANK_TEXT_VALIDATION;
  }
  return text;
}

function checkNullString(text) {
  if (text === undefined) return "NA";
  if (text === null) return "NA";
  if (text.length == 0) {
    return "NA";
  }
  return text;
}

function checkNullValidation(data) {
  if (
    data == null ||
    data == undefined ||
    data == "null" ||
    data == "undefined" ||
    data.length == 0
  ) {
    return true;
  }
  return false;
}

function getUrl(resumeLink) {
  if (resumeLink?.split(".")[1] == "pdf") {
    return RESUME_BASEURL + resumeLink + "#search=which";
  }
  //  return`https://docs.google.com/gview?url=${RESUME_BASEURL +
  //  resumeLink.replace(/\s/g, "%20")}&embedded=true`
  return (
    CommonString.doc_pre_extention +
    RESUME_BASEURL +
    resumeLink?.replace(/\s/g, "%20") +
    CommonString.doc_post_extention
  );
}

function checkNull(data) {
  let status = false;
  data.map((e) => {
    if (e.length == 0) {
      status = true;
      return;
    }
  });
  return status;
}
function formatName(name) {
  if (name === undefined) return;
  var finalName = "";
  var tempname = name.split(" ");
  tempname.forEach((element) => {
    finalName = `${finalName} ${element.substring(0, 1).toUpperCase()}${element
      .substring(1, element.length + 1)
      .toLowerCase()}`;
  });
  return finalName;
}

function getDate(data) {
  if (data.due_date === null || data.due_date === undefined) {
    if (data.job_detail_due_date === "") {
      return "open";
    }
    return moment(data.job_detail_due_date).format(DATE_FORMATE);
  }

  return moment(data.due_date).format(DATE_FORMATE);
}

function findEmailFromString(text) {
  // var result = [];
  // const strPattern = ["\\S+@\\S+"];
  // for (let i = 0; i < 1; i++) {
  //   const pattern = new RegExp(strPattern[i], "g");
  //   let matches;
  //   while ((matches = pattern.exec(text)) !== null) {
  //     result.push(...matches);
  //   }
  // }
  // return removeDuplicates(result);
  var result = text.match(
    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
  );
  return removeDuplicates(result);
}
function findPhoneFromString(text) {
  if (text === null || text === undefined) return;
  var re = /(?:[-+() ]*\d){10,13}/gm;
  var res = text.match(re)?.map(function (s) {
    return s.trim();
  });
  return removeDuplicates(res);
}
function removeDuplicates(arr) {
  if (arr === undefined || arr === null) return;
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
function checkUndefinedValue(text) {
  if (text === undefined || text === null) {
    return true;
  }
  return false;
}
export {
  checkUndefinedValue,
  checkString,
  getUrl,
  checkNull,
  formatName,
  checkNullString,
  checkNullValidation,
  getDate,
  findEmailFromString,
  findPhoneFromString,
};
