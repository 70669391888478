import ApiUtils from "../APICallServices/APIUtils";

export default class RecruiterProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  addToken = (data) => {
    return this.Apiprovider.init().post("recruiter/add-token-ats", data);
  };
  getRecruiterList = () => {
    return this.Apiprovider.init().get("recruiter/get-recruiter");
  };
  addNewRecruiter = (data) => {
    return this.Apiprovider.init().post("recruiter/add-recruiter", data);
  };
  updateRecruiter = (data) => {
    return this.Apiprovider.init().post("recruiter/update-recruiter", data);
  };
  searchRecruiter = (data) => {
    return this.Apiprovider.init().post("recruiter/search-recruiter", data);
  };
  deleteRecruiter = (data) => {
    return this.Apiprovider.init().post("recruiter/delete-recruiter", data);
  };
  UploadRecruiterProfile = (data) => {
    return this.Apiprovider.init().post("upload-profile", data);
  };
  getRecruiterProfile = (data) => {
    return this.Apiprovider.init().get(`profile/${data}`);
  };

  getUserProfessionalInfo = (data) => {
    return this.Apiprovider.init().post(
      `recruiter/get-recruiter-personal-details-by-id`,
      data
    );
  };
  getUserPersonalBodyInfo = (data) => {
    return this.Apiprovider.init().post(
      `recruiter/get-recruiter-personal-body-details-by-id`,
      data
    );
  };
  getOfficeTime = () => {
    return this.Apiprovider.init().get("applicant/get-office-time");
  };
  getAccessDataById = (data) => {
    return this.Apiprovider.init().post("v2/ats_access/get-by-id", data);
  };
  updateAccessDataById = (data) => {
    return this.Apiprovider.init().post("v2/ats_access/update", data);
  };
  updateInternalDomain = (data) => {
    return this.Apiprovider.init().post(
      "v2/internal_domain_recruiter/update_by_user_id",
      data
    );
  };
  getInternalDomain = (data) => {
    return this.Apiprovider.init().post(
      "v2/internal_domain_recruiter/get_by_user_id",
      data
    );
  };
  getInternalDomainUserList = () => {
    return this.Apiprovider.init().get(
      "v2/internal_domain_recruiter/get_user_list"
    );
  };
}
