import React from "react";
import { Button } from "semantic-ui-react";

export default function CustomCommonButton(props) {
  const { name, onClick, display } = props;

  return (
    <Button
      size="small"
      style={{
        display: display ? "inherit" : "none",
        background: "#BEC5CB",
      }}
      {...props}
      onClick={onClick}
    >
      <b>{name}</b>
    </Button>
  );
}
