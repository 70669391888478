import React from "react";
import { FlexContainer } from "../../style/common.style";
import { onChangeComment } from "../../feature/store/ApplicantStore";
import { CommonString } from "../../Utils/CommonString";
import Lottie from "react-lottie";
import CustomButton from "../CommonComponet/Button";
import loader from "../../assets/images/loader3.json";
import ApplicantCustomController from "./ApplicantCustomController";
import { useDispatch } from "react-redux";
import CustomCommonButton from "../CommonComponet/CustomCommonButton";

export default function AddComment(data) {
  const dispatch = useDispatch();
  const { loader_for_comment, comment, onAddComment } =
    ApplicantCustomController();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <FlexContainer className="commentContainer">
      <input
        className="mr-3"
        value={comment || ""}
        onChange={(e) => dispatch(onChangeComment(e.target.value))}
        placeholder={CommonString.add_comment}
      />
      {loader_for_comment ? (
        <div style={{ width: 100 }}>
          <Lottie options={defaultOptions} height={25} width={25} />
        </div>
      ) : (
        <CustomCommonButton
          display={true}
          noMarginTop={true}
          onClick={() => onAddComment(data)}
          name={CommonString.add}
        />
      )}
    </FlexContainer>
  );
}
