import moment from "moment";
import React from "react";
import { DATE_FORMATE } from "../../Utils/constant";
import { SubTitle } from "../../style/common.style";

export default function CommentTable(props) {
  const comment_list = props.data?.comments;

  return comment_list?.length === 0 ? (
    <></>
  ) : (
    <div>
      <SubTitle>Comment Details</SubTitle>
      <table className="tootTipTable">
        <tr>
          <th>Comment</th>
          <th>Commented Date</th>
          <th>Commented Time</th>
          <th>Commented By</th>
        </tr>
        {comment_list?.map((data, index) => (
          <tr key={index}>
            <td>{data.comment}</td>
            <td className="text-nowrap">
              {moment(data.createdAt).format(DATE_FORMATE)}
            </td>
            <td className="text-nowrap">
              {moment(data.createdAt).format("HH:mm")}
            </td>
            <td className="text-nowrap">{data.commentedby.name}</td>
          </tr>
        ))}
      </table>
    </div>
  );
}
