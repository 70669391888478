import React, { useEffect, useState } from "react";
import CustomTooltip from "../CommonComponet/Tooltip";
import TextWithIcon from "../CommonComponet/TextWithIcon";
import {
  AddCommentText,
  HR,
  ReadMoreReadLessContent,
  ReadMoreReadLessText,
} from "../../style/common.style";
import { CommonString } from "../../Utils/CommonString";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  ApplicantAddedBy,
  ApplicantAddedByContainer,
  IsVisibleSkill,
  RowTileContainer,
  RowTileTitle,
} from "../../style/applicant.style";
import { CommonColor } from "../../Utils/color";
import { formatPhoneNumber } from "../../Utils/utilitiFunction";
import ApplicantCustomController from "./ApplicantCustomController";
import CustomCommonButton from "../CommonComponet/CustomCommonButton";
import CommentTable from "./CommentTable";
import GetTextWithIconAndCopy from "./GetTextWithIconAndCopy";
import { useDispatch } from "react-redux";
import { onChangeComment } from "../../feature/store/ApplicantStore";
import AddComment from "./AddComment";

export default function ApplicantCard(props) {
  const dispatch = useDispatch();
  const {
    onClickApplicant,
    onClickOnCloseButton,
    onClickOnEditButton,
    getCityName,
    getFullName,
    selected_applicant,
    access_data_by_user_id,
  } = ApplicantCustomController();

  const [isVisible, setIsVisible] = useState(false);
  const [onClickReadmore, setonClickReadmore] = useState(true);

  useEffect(() => {
    dispatch(
      onChangeComment(props.data.comment === "--" ? "" : props.data.comment)
    );
  }, []);

  return (
    <RowTileContainer
      isActive={selected_applicant?._id === props.data?._id}
      onClick={() => onClickApplicant(props.data)}
    >
      <ApplicantAddedByContainer>
        <RowTileTitle>{getFullName(props.data)}</RowTileTitle>
        <ApplicantAddedBy>{props.data.added_by}</ApplicantAddedBy>
      </ApplicantAddedByContainer>
      <TextWithIcon
        style={{ color: CommonColor.primary_color }}
        color={CommonColor.primary_color}
        icon={CommonIcon.bag}
        text={props.data.designation}
      />
      <div>
        {GetTextWithIconAndCopy(props.data.primary_email, CommonIcon.email)}
        {GetTextWithIconAndCopy(
          formatPhoneNumber(props.data.phone),
          CommonIcon.call
        )}
      </div>
      <TextWithIcon icon={CommonIcon.map} text={getCityName(props.data)} />
      <HR data={props.data.skills.length === 0 || props.data.skills === "--"} />
      <IsVisibleSkill data={props.data}>
        {props.data.skills.trim().length >= 100 ? (
          <>
            <ReadMoreReadLessContent onClickReadmore={onClickReadmore}>
              {props.data.skills}
            </ReadMoreReadLessContent>
            <ReadMoreReadLessText
              onClick={(e) => {
                e.stopPropagation();
                setonClickReadmore(!onClickReadmore);
              }}
            >
              {onClickReadmore
                ? CommonString.read_more
                : CommonString.read_less}
            </ReadMoreReadLessText>
          </>
        ) : (
          props.data.skills
        )}
      </IsVisibleSkill>
      <HR data={props.data.comment === ""} />
      <CustomTooltip
        placement={CommonString.right_end_placement}
        title={<CommentTable data={props.data} />}
        name={
          <p>
            {props.data?.comments === undefined
              ? ""
              : props.data?.comments.slice()[0]?.comment}
          </p>
        }
      />
      <AddCommentText
        onClick={(e) => {
          e.stopPropagation();
          setIsVisible(true);
        }}
      >
        {CommonString.add_comment}
      </AddCommentText>
      {isVisible ? <AddComment data={props.data} /> : <></>}
      <div className="mt-3 flex">
        <CustomCommonButton
          display={access_data_by_user_id.includes("applicant_update")}
          basic
          name={"Edit"}
          onClick={() => onClickOnEditButton(props.data)}
        />
        <CustomCommonButton
          display={access_data_by_user_id.includes("close_applicant")}
          name={"Close"}
          onClick={() => onClickOnCloseButton(props.data)}
        />
      </div>
    </RowTileContainer>
  );
}
