import { Grid } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import {
  OutlinedBtn,
  ProfileContainer,
  ProfileUrl,
  SwitchWithTitle,
} from "../../style/common.style";
import FormInput from "../CommonComponet/FormInput";
import PasswordInputField from "../CommonComponet/PasswordInputField";
import CustomSwitch from "../CommonComponet/CustomSwitch";
import CustomDatePicker from "../CommonComponet/DatePicker";
import { CommonLabel, CommonString } from "../../Utils/CommonString";
import { PROFILE_BASEURL, TIME_FORMATE } from "../../Utils/constant";
import moment from "moment";
import RecruiterController from "../../controller/RecruiterController";
import userIcon from "../../assets/images/User.png";
import LevelProvider from "../../services/provider/LevelProvider";
import CustomButton from "../CommonComponet/Button";
import { CommonIcon } from "../../Utils/CommonIcon";
import { AddProfileIcon, ButtonContainer } from "../../style/recruiter.style";
import CustomDropdown from "../basic/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import {
  ADD_RECRUITER_ERROR,
  ADD_RECRUITER_SUCCESS,
  UPDATE_RECRUITER_ERROR,
  UPDATE_RECRUITER_SUCCESS,
} from "../../Utils/alertMessage";
import {
  getUserDataWhileAddingUser,
  onChangeSelectUser,
} from "../../feature/store/UserStore";
import SearchLoader from "../CommonComponet/SearchLoader";
import LoaderForTable from "../CommonComponet/LoaderForTable";
import { Input } from "semantic-ui-react";

export default function BasicInfoForm(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  var levelProvider = new LevelProvider();
  var recruiterController = new RecruiterController();
  const inputRef = createRef();
  const selected_user = useSelector((state) => state.UserStore.selected_user);
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [birth_date, setBirth_date] = useState(null);
  const [levelList, setLevelList] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [selectedShiftData, setSelectedShiftData] = useState();
  const [selectedValueLevel, setSelectedValueLevel] = useState();
  const [profileUrl, setProfileUrl] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    about: "",
    employee_id: "", // Added employeeID here
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({}); // State for validation errors

  useEffect(() => {
    getOfficeShiftTime();
    getLevelData();
  }, []);

  useEffect(() => {
    onPrefilledData();
  }, [shiftData, levelList]);

  async function onPrefilledData() {
    if (selected_user != null || selected_user != undefined) {
      let FilterLevelList;
      const filteredPreShift = await shiftData.filter(
        (time) => time._id === selected_user.shift_id
      );
      FilterLevelList = levelList.filter(
        (data) => data.name === selected_user.level_name
      );
      setSelectedValueLevel(FilterLevelList[0]);
      setSelectedShiftData(filteredPreShift[0]);
      setProfileUrl(selected_user.profile_image_url);
      setIsRecruiter(selected_user.is_recruiter);
      setBirth_date(selected_user.birthday);
      formData.name = selected_user.name;
      formData.email = selected_user.email;
      formData.phone = selected_user.phone;
      formData.password = selected_user.password;
      formData.about = selected_user.about;
      formData.employee_id = selected_user.employee_id;
      dispatch(getUserDataWhileAddingUser(selected_user));
    }
  }
  function onUploadProfile(e) {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    var res = recruiterController
      .UploadRecruiterProfile(formData)
      .then((res) => {
        setProfileUrl(res.data.data.url);
      });
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  async function getLevelData() {
    var data = await levelProvider.getLevelList();
    setLevelList(data.data.data);
  }

  async function getOfficeShiftTime() {
    var res = await recruiterController.getOfficeTime();
    setShiftData(res);
    return res;
  }

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    // Check required fields
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    else if (!emailPattern.test(formData.email))
      newErrors.email = "Email is invalid.";
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (!selectedValueLevel) newErrors.level = "Level is required.";
    if (!formData.employee_id)
      newErrors.employee_id = "Prexelite ID is required.";
    if (!birth_date) newErrors.birth_date = "Birth date is required.";
    if (!selectedShiftData) newErrors.shift = "Shift time is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  async function onAddAccess(isClose) {
    // if (await !validateForm()) return; // Validate form before proceeding
    // Prepare data for submission
    var filteredShift = shiftData.filter((time) => time.shift_type === "US");
    var sendData = {
      ...formData,
      level_id: selectedValueLevel ? selectedValueLevel._id : "",
      level_name: selectedValueLevel ? selectedValueLevel.name : "",
      is_recruiter: isRecruiter ? 1 : 0,
      profile_image_url: profileUrl ? profileUrl : "",
      birthday: birth_date,
      shift_id: selectedShiftData
        ? selectedShiftData._id
        : filteredShift[0]._id,
    };
    setIsLoading(true);
    if (selected_user != null || selected_user != undefined) {
      var data = {
        _id: selected_user?._id,
        ...sendData,
      };
      var res = await recruiterController.updateRecruiter(data);
      if (res.data.status === 200) {
        alert.show(UPDATE_RECRUITER_SUCCESS, { type: CommonString.success });
      } else {
        alert.show(UPDATE_RECRUITER_ERROR, { type: CommonString.error });
      }
      setTimeout(() => {
        if (isClose) {
          setIsLoading(false);
          props.onChange();
        } else {
          setIsLoading(false);
          props.handleClose();
        }
      }, 2000);
      return;
    }
    var add_res = await recruiterController.addRecruiter(sendData);
    if (add_res.data.status === 200) {
      dispatch(onChangeSelectUser(add_res.data.data));
      alert.show(ADD_RECRUITER_SUCCESS, { type: CommonString.success });
    } else {
      alert.show(ADD_RECRUITER_ERROR, { type: CommonString.error });
    }
    // Handle tab change or close modal
    setTimeout(() => {
      if (isClose) {
        setIsLoading(false);
        props.onChange();
      } else {
        setIsLoading(false);
        props.handleClose();
      }
    }, 2000);
  }
  return (
    <>
      <Grid container>
        <Grid item md={3}>
          <input
            ref={inputRef}
            style={{ display: "none" }}
            name="Profile"
            type="file"
            onChange={(e) => onUploadProfile(e)}
          />
          <ProfileContainer>
            {profileUrl !== "" ? (
              <div style={{ position: "relative" }}>
                <ProfileUrl
                  profileUrl={profileUrl}
                  src={PROFILE_BASEURL + profileUrl}
                />
                <AddProfileIcon
                  onClick={() => inputRef.current.click()}
                  icon={CommonIcon.camera}
                />
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <ProfileUrl profileUrl={userIcon} src={userIcon} />
                <AddProfileIcon
                  onClick={() => inputRef.current.click()}
                  icon={CommonIcon.camera}
                />
              </div>
            )}
          </ProfileContainer>
        </Grid>
        <Grid item md={9}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormInput
                required
                value={formData.name || ""}
                onChange={handleInputChange}
                placeholder="Name *"
                name="name" // corrected name attribute
                error={errors.name} // Display error if exists
              />
              <FormInput
                required
                type="number"
                value={formData.phone || ""}
                onChange={handleInputChange}
                placeholder="Phone No. *"
                name="phone"
                error={errors.phone} // Display error if exists
              />
            </Grid>
            <Grid item md={6}>
              <FormInput
                type="email"
                autoComplete="off"
                onChange={handleInputChange}
                value={formData.email || ""}
                placeholder="Email *"
                name="email"
                error={errors.email} // Display error if exists
              />
              <PasswordInputField
                onChange={handleInputChange}
                value={formData.password || ""}
                placeholder="Password *"
                name="password"
                error={errors.password} // Display error if exists
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* <SelectTextFields
          placeholder="Level Name *"
          value={selectedValueLevel}
          onChange={(e) => setSelectedValueLevel(e)}
          options={levelList}
          getOptionLabel={(option) =>
            option?.name + " ( " + option?.designation + " ) "
          }
          error={errors.level} // Display error if exists
        /> */}
        <Grid item md={3}>
          <CustomDropdown
            placeholder="Level Name *"
            value={selectedValueLevel}
            onChange={(e) => setSelectedValueLevel(e)}
            options={levelList}
            getOptionLabel={(option) =>
              option?.designation + " ( " + option?.name + " )"
            }
            error={errors.level}
          />
        </Grid>
        <Grid item md={3}>
          <FormInput
            value={formData.employee_id || ""}
            onChange={handleInputChange}
            placeholder="Prexelite ID *"
            name="employee_id"
            error={errors.employee_id} // Display error if exists
          />
        </Grid>
        <Grid item md={3}>
          <CustomDatePicker
            value={birth_date}
            onChange={(date) => setBirth_date(date)}
            name={CommonLabel.birth_date}
            label={CommonLabel.birth_date}
            error={errors.birth_date} // Display error if exists
          />
        </Grid>
        <Grid item md={3}>
          <CustomDropdown
            placeholder="Shift Time *"
            value={selectedShiftData}
            onChange={(e) => setSelectedShiftData(e)}
            options={shiftData}
            getOptionLabel={(option) =>
              option?.shift_type +
              " ( " +
              moment(option?.in_time).format(TIME_FORMATE) +
              " - " +
              moment(option?.out_time).format(TIME_FORMATE) +
              " )"
            }
            error={errors.shift} // Display error if exists
          />
        </Grid>
      </Grid>
      <FormInput
        multiline
        value={formData.about || ""}
        onChange={handleInputChange}
        placeholder="About"
        name="about"
      />
      {/* <Input label='PREXELITE' placeholder='00001' /> */}

      <SwitchWithTitle>
        <b>{"Recruiter\xa0\xa0\xa0:\xa0\xa0\xa0"}</b>
        <CustomSwitch
          checked={isRecruiter}
          onChange={(e) => setIsRecruiter(e.target.checked)}
        />
      </SwitchWithTitle>
      <Grid item md={12}>
        <ButtonContainer>
          <OutlinedBtn onClick={() => onAddAccess(false)}>
            Save & Exit
          </OutlinedBtn>
          <CustomButton onClick={() => onAddAccess(true)} name={"Next"} />
        </ButtonContainer>
      </Grid>
      {isLoading ? <LoaderForTable /> : <></>}
    </>
  );
}
