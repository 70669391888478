import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";

export default function SelectMultiple(props) {
  const [selectedValues, setSelectedValues] = useState(
    props.selectedRecruiterList
  );
  const formattedOptions = props.list.map((option) => ({
    key: option?._id,
    value: option._id,
    text: option.name.split(" ")[0],
  }));
  const handleChange = (e, { value }) => {
    setSelectedValues(value);
    props.handleChange(value);
  };

  return (
    <div className="" style={{ width: 200 }}>
      <Dropdown
        // defaultOpen={props.selectedRecruiterList}
        placeholder="Select Users"
        fluid
        multiple
        selection
        options={formattedOptions}
        value={selectedValues}
        search
        onChange={handleChange}
      />
      {/* <DropdownMenu>
          <DropdownHeader icon="tags" content="Filter by tag" />
          <DropdownDivider />
          <DropdownItem
            label={{ color: "red", empty: true, circular: true }}
            text="Important"
          />
      
        </DropdownMenu> */}
      {/* <label>Select Recruiter</label>
      <br />
      <FormControl>
        <Select
          style={{ border: "1px solid lightgrey", width: 200, padding: 0 }}
          multiple
          displayEmpty
          value={props.selectedRecruiterList}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            return (
              <span
                style={{
                  marginLeft: 6,
                  width: 210,
                  overflowX: "auto",
                  padding: 0,
                }}
              >
                {selected.join(", ")}
              </span>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {props.list.map((data) => (
            <MenuItem key={data} value={data?.name}>
              <CheckBoxContainer>
                <FormLabel>
                  <input
                    ref={inputClick}
                    id="default-checkbox"
                    type="checkbox"
                    name={data?.name}
                    onChange={(event) => props.handleChange(event, data)}
                    checked={props.selectedRecruiterList.includes(data?.name)}
                    value=""
                    placeholder="Select Recruiter"
                  />
                </FormLabel>
                <FormLabel>{data?.name}</FormLabel>
              </CheckBoxContainer>
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </div>
  );
}
