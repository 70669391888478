import ApiUtils from "../APICallServices/APIUtils";

export default class SubmissionProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  addSubmission = (data) => {
    // return this.Apiprovider.init().post("submition/add-submition", data);
    return this.Apiprovider.init().post("v2/submissionV2/add", data);
  };
  getSubmission = (page) => {
    return this.Apiprovider.init().get(`v2/submissionV2/get/${page}`);
  };
  getSubmissionWithStatus = (page) => {
    return this.Apiprovider.init().get(
      `submition/get-submition-with-status?page=${page}`
    );
  };
  getSubmissionByRecruiterID = (page, data) => {
    return this.Apiprovider.init().post(
      `/v2/submissionV2/get-by-recruiter/${page}`,
      data
    );
  };
  searchSubmission = (data, page) => {
    return this.Apiprovider.init().post(
      "v2/submissionV2/get-new-search/" + page,
      data
    );
  };
  // changeSubmissionStatus = (data) => {
  //   return this.Apiprovider.init().post(
  //     "submition/change-submition-status",
  //     data
  //   );
  // };
  changeSubmissionStatus = (data) => {
    return this.Apiprovider.init().post("v2/submissionV2/change-status", data);
  };
  getTrackerDataById = (data) => {
    return this.Apiprovider.init().post(
      "submition/get-submition-tracker-by-id",
      data
    );
  };
  updateSubmissionTracker = (data) => {
    return this.Apiprovider.init().post("v2/submission_tracker/update", data);
  };
  getInterviewDetailById = (data) => {
    return this.Apiprovider.init().post(
      "submition/get-interview-details-by-id",
      data
    );
  };
  deleteSubmission = (data) => {
    return this.Apiprovider.init().post("submition/delete-submission", data);
  };
  getSubmissionByChildJob = (data) => {
    return this.Apiprovider.init().post(
      "submition/get-submission-by-child-job-id",
      data
    );
  };
  getSubmissionByApplicant = (data) => {
    return this.Apiprovider.init().post(
      "submition/get-submission-by-applicant-id",
      data
    );
  };
  getSubmissionByStatus = (data) => {
    return this.Apiprovider.init().post(
      "submition/get-submition-by-status",
      data
    );
  };
  getSubmissionByStatusV2 = (data) => {
    return this.Apiprovider.init().post(
      "v2/interview_details/get-submission-by-status-dashboard",
      data
    );
  };
  addSubmissionOfferDetail = (data) => {
    return this.Apiprovider.init().post("v2/submission_v2_details", data);
  };
  addProjectCompletedDetail = (data) => {
    return this.Apiprovider.init().post("v2/submission_addition_detail", data);
  };
  updateProjectCompletedDetail = (data) => {
    return this.Apiprovider.init().post(
      "v2/submission_addition_detail/update",
      data
    );
  };
  getProjectCompletedDetail = (data) => {
    return this.Apiprovider.init().post(
      "v2/submission_addition_detail/get-by-id",
      data
    );
  };
  getSubmissionOfferDetail = (data) => {
    return this.Apiprovider.init().post(
      "v2/submission_v2_details/get-by-id",
      data
    );
  };
  UpdateInterviewDetail = (data) => {
    return this.Apiprovider.init().post(
      "submition/update-interview-details",
      data
    );
  };
  SearchDataInChatBox = (data) => {
    return this.Apiprovider.init().post(
      "submition/search-submition-with-status",
      data
    );
  };
  getChildjobsByParent = (data) => {
    return this.Apiprovider.init().post(
      "v2/submissionV2/get-child-by-parent-job",
      data
    );
  };
  onAddParnerInSubmission = (data) => {
    return this.Apiprovider.init().post("v2/submissionV2/update-partner", data);
  };
  onGetSubmissionTrackerData = (data) => {
    return this.Apiprovider.init().post(
      "v2/submission_tracker/get-submission_tracker-by-recruiter-id",
      data
    );
  };
  onEditOfferDetail = (data) => {
    return this.Apiprovider.init().post(
      "v2/submission_v2_details/update",
      data
    );
  };
  onFilterData = (data, page) => {
    return this.Apiprovider.init().post(
      `v2/submissionV2/filter-status/${page}`,
      data
    );
  };
  // getStatusDataForDashboard = (data) => {
  //   return this.Apiprovider.init().post(
  //     `v2/submissionV2/get_submission__count_details`,
  //     data
  //   );
  // };
  getStatusDataForDashboard = (data) => {
    return this.Apiprovider.init().post(
      `v2/submissionV2/get_submission_count_details`,
      data
    );
  };
  getStatusDataByIdAction = (data) => {
    return this.Apiprovider.init().post(
      `v2/submissionV2/get-by-submission-id`,
      data
    );
  };

  // InterviewDataInDashboard = (data) => {
  //   return this.Apiprovider.init().post(
  //     "submition/search-submition-with-status",
  //     data
  //   );
  // };
}
