import { SubmissionController } from "../../controller/SubmissionController";
import { useDispatch, useSelector } from "react-redux";
import {
  changeButtonName,
  changeIsApplicantSearching,
  changeIsDataNull,
  changeIsLoadingSubmissionData,
  changeIsOpenModal,
  changeIsOpenSubmissionDetail,
  changeSelectedFileName,
  changeSelectedSubmissionDataForApplicant,
  changeSubmissionDataForApplicant,
  onChangeComment,
  onChangeIsLoadingForSearch,
  onChangeLoaderForComment,
  onChangeLoadMoreData,
  onChangeSearchText,
  setApplicantData,
  setSearchData,
  setSelectedApplicant,
  updateApplicantData,
} from "../../feature/store/ApplicantStore";
import { checkString, formatName, getUrl } from "../../Utils/validation";
import { SELECTE_PARTNER, VIEW_JOBS, VIEW_SUBMISSION } from "../../Utils/enum";
import { useRef } from "react";
import {
  ButtonName,
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import { changeInitialLoader } from "../../feature/store/ReduxStore";
import ApplicantContoller from "../../controller/ApplicantContoller";
import AddApplicant from "../Modal/AddApplicant";
import LockApplicant from "../ViewDetails/LockApplicant";
import ApplicantDataDetails from "../ViewDetails/ApplicantDataDetails";
import { UPDATE_APPLICANT_SUCCESS } from "../../Utils/alertMessage";
import { useAlert } from "react-alert";

export default function ApplicantCustomController() {
  const dispatch = useDispatch();
  const submissionController = new SubmissionController();
  const applicantController = new ApplicantContoller();
  const consultantRef = useRef(null);
  const listInnerRef = useRef();
  const alert = useAlert();
  const selected_applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  const is_open_submission_detail = useSelector(
    (state) => state.ApplicantStore.is_open_submission_detail
  );
  const submission_data_for_applicant = useSelector(
    (state) => state.ApplicantStore.submission_data_for_applicant
  );
  const selected_submission_data_for_applicant = useSelector(
    (state) => state.ApplicantStore.selected_submission_data_for_applicant
  );
  const is_loading_submission_data = useSelector(
    (state) => state.ApplicantStore.is_loading_submission_data
  );
  const is_applicant_searching = useSelector(
    (state) => state.ApplicantStore.is_applicant_searching
  );
  const initial_loader = useSelector((state) => state.counter.initial_loader);
  const searchResult = useSelector(
    (state) => state.ApplicantStore.searchResult
  );
  const searchPageNumber = useSelector(
    (state) => state.ApplicantStore.searchPageNumber
  );
  const applicantList = useSelector((state) => state.ApplicantStore.applicants);
  const search_text = useSelector((state) => state.ApplicantStore.search_text);
  const is_data_null = useSelector(
    (state) => state.ApplicantStore.is_data_null
  );
  const pageNumber = useSelector((state) => state.ApplicantStore.pageNumber);
  const loader_for_comment = useSelector(
    (state) => state.ApplicantStore.loader_for_comment
  );
  const comment = useSelector((state) => state.ApplicantStore.comment);
  const is_loading_for_search = useSelector(
    (state) => state.ApplicantStore.is_loading_for_search
  );
  const load_more_data = useSelector(
    (state) => state.ApplicantStore.load_more_data
  );
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  async function getSubmissionData(data) {
    if (data === undefined) return;
    dispatch(changeIsLoadingSubmissionData(true));
    var sendData = {
      applicant_id: data?._id,
    };
    var res = await submissionController.getSubmissionByApplicant(sendData);
    dispatch(changeSubmissionDataForApplicant(res.data.data));
    dispatch(changeIsLoadingSubmissionData(false));
  }
  const handleOpenPdf = (url) => {
    window.open(getUrl(url));
  };
  function onViewSubmissionDetail(data) {
    dispatch(changeIsOpenSubmissionDetail(!is_open_submission_detail));
    dispatch(changeSelectedSubmissionDataForApplicant(data));
  }
  function onCloseSubmissionDetail() {
    dispatch(changeIsOpenSubmissionDetail(false));
  }
  function getTitle(modalStatus) {
    if (modalStatus === VIEW_JOBS) {
      return VIEW_JOBS;
    }
    if (modalStatus === VIEW_SUBMISSION) {
      return VIEW_SUBMISSION;
    }
    if (modalStatus === SELECTE_PARTNER) {
      return SELECTE_PARTNER;
    }
    return SELECTE_PARTNER;
  }
  async function onClickApplicant(data) {
    await getSubmissionData(data);
    dispatch(setSelectedApplicant(data));
  }
  function getCityName(data) {
    if (data === null) return;
    if (data.state === " " && data.zip === "" && data.city === " ") {
      return "";
    }
    if (data.city === " ") {
      return ` ${data.state.trim()}, ${data.zip}`;
    } else if (data.state === " " && data.zip === "") {
      return data.city;
    } else if (data.state === " ") {
      return `${data.city}, ${data.zip}`;
    }
    return `${data.city}, ${data.state.trim()}, ${data.zip}`;
  }
  const scrollToAbout = () => {
    consultantRef.current.scrollIntoView({ behavior: "smooth" });
  };
  function getFullName(data) {
    if (data === null || data === undefined) return;
    const first_name = formatName(data.first_name);
    const middle_name = formatName(data.middle_name);
    const last_name = formatName(data.last_name);
    return `${first_name} ${middle_name} ${last_name}`;
  }
  function onClickOnCloseButton(data) {
    onClickApplicant(data);
    dispatch(changeIsOpenModal(true));
    dispatch(changeButtonName(ButtonName.close_applicant));
  }
  function onClickOnEditButton(data) {
    onClickApplicant(data);
    dispatch(changeIsOpenModal(true));
    dispatch(changeButtonName(ButtonName.edit_applicant));
    dispatch(changeSelectedFileName(null));
  }
  function isVisibleLoadMoreButton() {
    return !(
      getList().length % 20 !== 0 ||
      getList().length === 0 ||
      initial_loader
    );
  }
  function getList() {
    if (is_applicant_searching) {
      return searchResult;
    } else {
      if (searchResult.length > 0)
        dispatch(setSearchData({ data: [], page: 1 }));
      return applicantList;
    }
  }
  async function intializationData(page) {
    if (page === 1) {
      dispatch(changeInitialLoader(true));
    } else {
      dispatch(onChangeLoadMoreData(true));
    }
    try {
      // if (page > 1) setIsSearchLoading(true);
      var data = await applicantController.getApplicants(page);
      dispatch(setApplicantData({ data: data, page }));
      if (data.length > 0 && page == 1) onClickApplicant(data[0]);
      // setIsLoading(false);
      // setTimeout(() => {
      //   setIsSearchLoading(false);
      // }, 800);
    } catch (error) {
      // setIsLoading(false);
      // setIsSearchLoading(false);
    }
    dispatch(onChangeLoadMoreData(false));
    dispatch(changeInitialLoader(false));
  }
  async function onSearch(e, page) {
    try {
      dispatch(changeIsApplicantSearching(true));
      dispatch(onChangeIsLoadingForSearch(true));
      // dispatch(changeIsDataNull(true));
      if (page == 1) e.preventDefault();
      var sendData = {
        search_query: checkString(search_text.search_query),
        search_word: checkString(search_text.search_word),
      };
      var data = await applicantController.searchApplicant(sendData, page);
      // setIsSearchLoading(false);
      dispatch(onChangeIsLoadingForSearch(false));
      if (data.length === 0) {
        dispatch(changeIsDataNull(true));
        // setIsSearchLoading(false);
        return;
      }
      dispatch(changeIsDataNull(false));
      if (page == 1) {
        dispatch(setSearchData({ data: [...data], page: page }));
        if (data.length > 0) onClickApplicant(data[0]);
      } else {
        dispatch(
          setSearchData({ data: [...searchResult, ...data], page: page })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(onChangeIsLoadingForSearch(false));
      // setIsSearchLoading(false);
    }
  }
  function onChangeSearch(key, value) {
    if (key === "keyStringSearchText") {
      if (value.length === 0) {
        dispatch(changeIsApplicantSearching(false));
        onClickApplicant(applicantList[0]);
        dispatch(changeIsDataNull(false));
      }
      dispatch(
        onChangeSearchText({
          search_query: value,
          search_word: search_text.search_word,
        })
      );
    }
    if (key === "nameSearchText") {
      if (value.length === 0) {
        dispatch(changeIsApplicantSearching(false));
        onClickApplicant(applicantList[0]);
        dispatch(changeIsDataNull(false));
      }
      dispatch(
        onChangeSearchText({
          search_query: search_text.search_query,
          search_word: value,
        })
      );
    }
  }
  function getFormView(button_name) {
    if (button_name === ButtonName.add_applicant) {
      return <AddApplicant />;
    } else if (button_name === ButtonName.edit_applicant) {
      return <AddApplicant />;
    } else if (button_name === ButtonName.close_applicant) {
      return <LockApplicant />;
    } else if (button_name === ButtonName.view_applicant) {
      return <ApplicantDataDetails />;
    }
  }
  const handleScroll = (e) => {
    if (getList().length % 20 !== 0) return;
    if (
      search_text.search_query.length > 0 ||
      search_text.search_word.length > 0
    ) {
      onSearch(listInnerRef, searchPageNumber + 1);
    } else {
      intializationData(pageNumber + 1);
    }
  };
  function onClickOnAddButton(e) {
    e.stopPropagation();
    dispatch(changeIsOpenModal(true));
    dispatch(changeButtonName(ButtonName.add_applicant));
    dispatch(changeIsDataNull(false));
  }
  async function onAddComment(data) {
    dispatch(onChangeLoaderForComment(true));
    let user = localStorage.getItem(CommonStringForLocalStorage.user);
    var sendData = {
      recruiter_id: JSON.parse(user).user._id,
      comment: comment,
      applicant_id: data?._id,
      createdAt: new Date().toISOString(),
      commentedby: JSON.parse(user).user.name,
    };
    var newCommentArray = [sendData, ...data.comments];
    // return;
    // if (length === 0) {
    let res = await applicantController.addComment(sendData);
    alert.show(UPDATE_APPLICANT_SUCCESS, { type: CommonString.success });
    dispatch(onChangeComment(""));
    dispatch(
      updateApplicantData({
        ...data,
        comments: newCommentArray,
      })
    );
    dispatch(onChangeLoaderForComment(false));
    // if (res.data.status === 200) {
    //
    //   alert.show(UPDATE_APPLICANT_SUCCESS, { type: CommonString.success });
    // } else {
    //   alert.show(UPDATE_APPLICANT_ERROR, { type: CommonString.error });
    // }
    // } else {
    //   let res = await applicatContoller.updateComment(sendData);
    //   if (res.data.status === 200) {
    //     dispatch(updateApplicantData({ ...props.data, ...sendData }));
    //     // alert.show(UPDATE_APPLICANT_SUCCESS, { type: CommonString.success });
    //   } else {
    //     // alert.show(UPDATE_APPLICANT_ERROR, { type: CommonString.error });
    //   }
    // }
  }
  return {
    load_more_data,
    search_text,
    is_loading_for_search,
    comment,
    loader_for_comment,
    onAddComment,
    access_data_by_user_id,
    onClickOnAddButton,
    handleScroll,
    initial_loader,
    getFormView,
    onChangeSearch,
    applicantList,
    onSearch,
    isVisibleLoadMoreButton,
    getList,
    intializationData,
    is_applicant_searching,
    onClickOnEditButton,
    onClickOnCloseButton,
    getFullName,
    scrollToAbout,
    consultantRef,
    getCityName,
    onClickApplicant,
    getTitle,
    selected_submission_data_for_applicant,
    selected_applicant,
    submission_data_for_applicant,
    getSubmissionData,
    handleOpenPdf,
    onViewSubmissionDetail,
    is_open_submission_detail,
    onCloseSubmissionDetail,
    is_loading_submission_data,
    is_data_null,
  };
}
