import React from "react";
import { TabPane, Tab } from "semantic-ui-react";
import JobTable from "../DataTable/Table/JobTable";
import { FlexContainer } from "../../style/dice.style";
import { ClearFilterText } from "../../style/common.style";
import JobCustomController from "../../controller/JobCustomController";
import { TabMenuContainer, TabSecondContainer } from "../../style/job.style";
import { useDispatch } from "react-redux";
import { onChangeTabName } from "../../feature/store/JobReduxStore";

const JobHome = () => {
  const dispatch = useDispatch();
  const {
    onClickClearFilter,
    getClearFilterName,
    job_count,
    multiple_recruiter_job_count,
    getData,
    getMultipleRecruiterData,
    filter_data,
  } = JobCustomController();
  const panes = [
    {
      menuItem: `Individual Requisition (${job_count})`,
      render: () => (
        <TabPane style={tabPanelStyle}>
          <JobTable is_multiple={false} />
        </TabPane>
      ),
    },
    {
      menuItem: `Multiple Requisition (${multiple_recruiter_job_count})`,
      render: () => (
        <TabPane style={tabPanelStyle}>
          <JobTable is_multiple={true} />
        </TabPane>
      ),
    },
  ];
  const handleTabChange = (e, { activeIndex }) => {
    if (activeIndex === 0) {
      dispatch(onChangeTabName("Individual"));
      getData(1);
    } else {
      dispatch(onChangeTabName("Multiple"));
      getMultipleRecruiterData(filter_data);
    }
  };
  return (
    <TabMenuContainer>
      <Tab
        defaultActiveIndex={0}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        onTabChange={handleTabChange}
      />
      <TabSecondContainer>
        <FlexContainer>
          {/* <span>
            {CommonString.total_job_count} : {job_count}
          </span> */}
          <ClearFilterText onClick={() => onClickClearFilter()}>
            {getClearFilterName()}
          </ClearFilterText>
          {/* <CustomizeTableFiled /> */}
        </FlexContainer>
      </TabSecondContainer>
    </TabMenuContainer>
  );
};

const tabPanelStyle = {
  border: "none",
  overflow: "hidden",
  padding: 0,
};
export default JobHome;
