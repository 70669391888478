import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useState } from "react";
import BaseModal from "../Modal/BaseModal";
import JobViewDetails from "../ViewDetails/JobViewDetails";
import SubmissionProvider from "../../services/provider/SubmissionProvider";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import {
  UPDATE_SUBMISSION_SUCCESS,
  UPDATE_SUBMISSION_ERROR,
  SELECTE_JOB,
} from "../../Utils/alertMessage";
import {
  addSubmissionData,
  changeMenuStatus,
} from "../../feature/store/ReduxStore";
import SelectPartner from "../Modal/SelectPartner";
import {
  SELECTE_PARTNER,
  SUBMISSION,
  UPDATE_APPLICANTS,
  VIEW_JOBS,
  VIEW_SUBMISSION,
} from "../../Utils/enum";
import {
  changeSelectedRecruiterId,
  changeTempJobList,
} from "../../feature/store/JobReduxStore";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import { CommonColor } from "../../Utils/color";
import { SUBMISSION_STATUS } from "../../Utils/constant";
import { checkNullValidation } from "../../Utils/validation";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";
import UpdateStatusConfirmation from "../Modal/UpdateStatusConfirmation";
import { IsOpenAlertBox } from "../../Utils/utilitiFunction";
import SubmissionViewDetails from "./SubmissionViewDetails";
import CloseApplicantMainView from "../Applicant/CloseApplicantMainView";
import ApplicantCustomController from "../Applicant/ApplicantCustomController";

export default function LockApplicant(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { getTitle } = ApplicantCustomController();
  const applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  const selectedJobForLockApplicant = useSelector(
    (state) => state.JobReduxStore.selectedJobForLockApplicant
  );
  const submissionProvider = new SubmissionProvider();
  const jobData = useSelector((state) => state.counter.jobData);
  const [selectedJob, setSelectedJob] = useState();
  const [isopenModal, setisOpenModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [progress, setProgress] = useState(0);
  const [modalStatus, setModalStatus] = useState();
  const [statusDetails, setStatusDetails] = useState("");
  const [isOpenAlertBox, setIsOpenAlertBox] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    setSearchResult(jobData);
    getData();
    // getJobData();
  }, []);

  function onSubmit(e) {
    setIsOpenAlertBox(true);
    setStatusDetails(e);
  }
  async function onUpdateData(e) {
    dispatch(onChangeIsLoading(true));
    if (checkNullValidation(selectedJobForLockApplicant)) {
      alert.show(SELECTE_JOB, { type: CommonString.info });
      dispatch(onChangeIsLoading(false));
      return;
    }
    var user = localStorage.getItem(CommonStringForLocalStorage.user);
    if (
      selectedJobForLockApplicant === null ||
      (e !== SUBMISSION_STATUS.IN_TOUCH && e.payrate !== undefined)
    ) {
      alert.show(SELECTE_JOB, { type: CommonString.info });
      dispatch(onChangeIsLoading(false));
      return;
    }
    var data = {
      applicant_name: `${applicant.first_name} ${applicant.last_name}`,
      full_name: `${applicant.first_name} ${applicant.last_name}`,
      job_name: selectedJobForLockApplicant.job_title,
      position_id: selectedJobForLockApplicant.client_position_id,
      parent_job_id: selectedJobForLockApplicant.job_id,
      child_job_id: selectedJobForLockApplicant._id,
      client_id: selectedJobForLockApplicant.client_id,
      client_name: selectedJobForLockApplicant.client_name,
      recruiter_id: JSON.parse(user).user._id,
      payrate: e === SUBMISSION_STATUS.IN_TOUCH ? 0 : e.payRate,
      tax_term: e === SUBMISSION_STATUS.IN_TOUCH ? 0 : e.tax_term,
      applicant_id: applicant._id,
      partner_id: selectedJobForLockApplicant.partner_id,
      applicant_first_name: applicant.first_name,
      applicant_last_name: applicant.last_name,
      department: selectedJobForLockApplicant.department,
      position_title: selectedJobForLockApplicant.job_title,
      job_id: selectedJobForLockApplicant.job_id,
      job_title: selectedJobForLockApplicant.job_title.trim(),
      partner_name: selectedJobForLockApplicant.partner_name,
      status:
        e === SUBMISSION_STATUS.IN_TOUCH
          ? SUBMISSION_STATUS.IN_TOUCH
          : SUBMISSION_STATUS.LOCKED,
      recruiter_name: JSON.parse(user).user.name,
      recruiter_email: JSON.parse(user).user.email,
      pay_rate: e === SUBMISSION_STATUS.IN_TOUCH ? 0 : e.payRate,
      comment: e?.comment ?? content,
    };
    var res = await submissionProvider.addSubmission(data);
    if (res.status === 200) {
      var partner_data = {
        ...res.data.data,
        position_id: selectedJobForLockApplicant.client_position_id,
        client_id: selectedJobForLockApplicant.client_id,
        client_name: selectedJobForLockApplicant.client_name,
      };
      dispatch(addSubmissionData(partner_data));
      setisOpenModal(false);
      // dispatch(onChangeIsLoading(false));
      dispatch(changeMenuStatus(SUBMISSION));
      alert.show(UPDATE_SUBMISSION_SUCCESS, { type: CommonString.success });
    } else if (res.status === 202) {
      dispatch(onChangeIsLoading(false));
      alert.show(res.data.message, { type: CommonString.info });
    } else {
      dispatch(onChangeIsLoading(false));
      alert.show(UPDATE_SUBMISSION_ERROR, { type: CommonString.error });
    }
  }
  function onViewModal(modalSt, data) {
    setisOpenModal(!isopenModal);
    setModalStatus(modalSt);
    setselectedData(data);
  }
  function closeSearchBox() {
    setSearchResult(jobData);
  }
  async function getData() {
    var user = JSON.parse(
      localStorage.getItem(CommonStringForLocalStorage.user)
    );
    dispatch(changeSelectedRecruiterId(user.user._id));
    dispatch(changeTempJobList(jobData));
  }

  function getFormView() {
    if (modalStatus === VIEW_JOBS) {
      return <JobViewDetails data={selectedData} />;
    }
    if (modalStatus === VIEW_SUBMISSION) {
      return (
        <SubmissionViewDetails
          handleClose={() => setisOpenModal(!isopenModal)}
          isLoading={(e) => setisLoading(e)}
          onRefresh={() => props.onRefresh()}
          data={selectedData}
        />
      );
    }
    return <SelectPartner onSubmit={onSubmit} data={jobData[selectedJob]} />;
  }
  var status_name =
    statusDetails === SUBMISSION_STATUS.IN_TOUCH
      ? SUBMISSION_STATUS.IN_TOUCH
      : SUBMISSION_STATUS.LOCKED;

  return (
    <div>
      <UpdateStatusConfirmation
        isLoading={false}
        status={status_name}
        possition_id={
          selectedJobForLockApplicant?.job_detail?.client_position_id
        }
        onClose={() => setIsOpenAlertBox(false)}
        onDelete={() => {
          onUpdateData(statusDetails);
          setIsOpenAlertBox(false);
        }}
        isOpenAlertBox={isOpenAlertBox}
        content={content}
        setContent={(e) => setContent(e)}
        enableInput={IsOpenAlertBox(status_name)}
      />
      <BaseModal
        isUpdate={modalStatus === UPDATE_APPLICANTS}
        isLoading={isLoading}
        isBig={modalStatus === SELECTE_PARTNER ? false : true}
        open={isopenModal}
        formTitle={getTitle(modalStatus)}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        {getFormView()}
      </BaseModal>
      <div onClick={closeSearchBox}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <CloseApplicantMainView
              onViewModal={onViewModal}
              onSubmit={onSubmit}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
