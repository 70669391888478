import styled, { keyframes } from "styled-components";
import { CommonColor } from "../Utils/color";
import { Grid } from "@mui/material";
import { SolidBtn } from "./common.style";
import { Icon } from "@iconify/react";
import { Button } from "@material-ui/core";

const ViewJobFormTitleContainer = styled.div`
  margin: 0 24px;
  margin-bottom: 10px !important;
  display: ${(props) => (props.isVisible ? "block" : "none")};
  margin-top: -8px;
`;
const ViewJobTitle = styled.span`
  font-size: 13px;
  color: ${(props) => (props.color ? props.color : "inherit")};
  font-weight: ${(props) => (props.color ? "bold" : "inherit")};
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  padding: ${(props) => (props.is_padding ? "0 25px" : "inherit")};
`;
const DateTableInJob = styled(Grid)`
  width: 100%;
  border: 1px solid ${CommonColor.border};
  margin: 10px 0;
  padding: 10px 15px;
  align-item: center;
  border-radius: 5px;
  span {
    display: flex;
    align-items: center;
    height: 100%;
  }
  input {
    width: 100%;
  }
`;
const List = styled.div`
  max-height: 100px;
  margin: 10px 0 !important;
  margin-bottom: 10px !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-flow: column wrap !important;
  flex-flow: column wrap !important;
  border: 0.7px solid #bfbebe;
  border-radius: 5px;
  overflow: auto;
  p {
    padding: 0 !important;
    color: ${CommonColor.primary_color} !important;
  }
  input::placeholder {
    font-size: 11px !important;
    color: grey !important;
  }
`;
const CustomTab = styled.div`
  display: flex;
  background-color: #e1e1e1;
  color: ${CommonColor.primary_color} !important;
  margin: 5px;
  align-items: center;
  padding: 5px 5px;
  border-radius: 25px;
  svg:hover {
    background: ${CommonColor.light_grey};
  }
`;
const CustomMenuContainer = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
  &:hover {
    background: ${CommonColor.primary_bg};
  }
`;
const FlexContainerForFilter = styled.div`
  display: flex !important;
  align-items: center !important;
  place-content: flex-start;
  margin: 5px;
  margin-top: 7px;
  margin-bottom: 10px;
  input {
    width: 100% !important;
  }
  .autocomplete {
    margin-top: 0 !important;
    margin-right: 5px;
    background-color: ${CommonColor.white};
  }
`;
const AddButtonForJob = styled(SolidBtn)`
  // position: absolute !important;
  // right: 10px;
  margin: 0 !important;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  h3,
  h6 {
    margin-bottom: 0;
  }
  h6 {
    margin: 0px ${(props) => (props.space ? "6px" : "0px")};
  }
`;
const JobViewDetailContainer = styled(Grid)`
  position: relative;
  img {
    width: 12px;
    margin-bottom: 2px;
  }
  p {
    margin-bottom: 2px;
    font-size: 11px;
  }
`;
const ConsultantTitle = styled.p`
  font-size: 13px !important;
  font-weight: bold;
`;
const ConsultantCard = styled.div`
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 27px 80px,
    rgba(0, 0, 0, 0.035) 0px 5.4px 13px;
  background-color: ${CommonColor.white};
`;
// const CustomButtonForJob =
//   styled.button <
//   { color: string, background: string } >
//   `
//   background-color: ${(props) =>
//     props.background ? props.background : CommonColor.primary_color};
//   color:${(props) => (props.color ? props.color : CommonColor.white)};

// border-radius: 0.375rem;
// line-height: 1.5rem;
// justify-content: center;
// display: flex;
// font-weight: 600;
// // padding:10px;
// width: auto;
// font-size: 11px;
// border-radius: 10px;
// padding:5px 15px;
// `;
// const OutlineButtonForJob =styled()`
//   color:${(props) =>
//     props.color ? props.color : CommonColor.primary_color} !important;
//   border:1px solid ${(props) =>
//     props.border ? props.border : CommonColor.primary_color} !important;
//   background-color:${(props) =>
//     props.isActive ? props.border : CommonColor.white} ;
//   border-radius: 10px;
// padding:5px 15px;
//   &:hover {
//     background-color: ${(props) =>
//       props.isActive ? props.border : CommonColor.light_grey}!important;
//   }
// `;
const FlexContainerForStatus = styled.div`
  display: flex;
  width: 200px;
  flex-wrap: wrap;
  margin: -5px;
  /* padding: 10px; */
  button {
    margin: 4px;
  }
`;
const JobTabContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${CommonColor.light_grey};
  border-radius: 8px;
  width: fit-content;
  color: ${CommonColor.black_opacity_color};
  padding: 7px;
  margin-bottom: 10px;
`;
const JobFormContainer = styled.div`
  padding: 0 50px;
  /* align-items: center; */
`;
const JobTab = styled.div`
  font-size: 11px;
  padding: 5px 20px;
  background-color: ${CommonColor.light_grey};
  color: ${(props) =>
    props.activeTab ? CommonColor.primary_color : CommonColor.dark_grey};
  border-radius: 5px;
  font-weight: 600;
  /* border-left:0.5px solid ${CommonColor.dark_grey}; */
  /* margin-bottom: 15px; */
  /* margin-right:10px; */
  cursor: pointer;
  white-space: nowrap;
  background-color: ${(props) =>
    props.activeTab ? CommonColor.white : CommonColor.transparent};
  box-shadow: ${(props) =>
    props.activeTab
      ? "0 1px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 3px 0 rgba(0, 0, 0, 0.15)"
      : "none"};
  /* border-bottom: 2px solid ${(props) =>
    props.activeTab ? CommonColor.primary_color : "none"}; */
`;
const AttachmentContainer = styled.div`
  border-radius: 15px;
  border: 0.7px dashed #253f50;
  background: rgba(37, 63, 80, 0.05);
  width: 100%;
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    /* border: 1px dotted ${CommonColor.primary_color}; */
  }
`;
const AttachmentName = styled.span`
  display: flex;
  align-items: center;
  border: 1px solid ${CommonColor.light_text_color};
  padding: 5px 10px;
  margin: 5px 0;
  border-radius: 10px;
  font-size: 11px;
  cursor: default;
  &:hover {
    background-color: ${CommonColor.light_grey};
  }
  svg {
    margin-left: 5px;
    width: 14px;
    height: 14px;
    &:hover {
      color: ${CommonColor.primary_color};
      cursor: pointer;
    }
  }
`;
const AttachmentIcon = styled(Icon)`
  font-size: 40px;
  color: ${CommonColor.primary_color};
  margin: 10px;
`;
const StatusContainer = styled.p`
  width: fit-content;
  /* text-align: center; */
  padding: 3px 10px;
  border-radius: 5px;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
`;
const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;
const Title = styled.h6`
  font-size: 14px;
  font-weight: 700;
  color: ${CommonColor.primary_color};
  span {
    color: ${CommonColor.secondary_color};
  }
`;
const CustomButtonForJob = styled.button`
  background-color: ${(props) =>
    props.background ? props.background : CommonColor.primary_color};
  color: ${(props) => (props.color ? props.color : CommonColor.white)};
  border-radius: 0.375rem;
  line-height: 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  // padding:10px;
  width: auto;
  font-size: 11px;
  border-radius: 10px;
  padding: 2px 15px;
  border: none;
  svg {
    width: 18px;
    height: 18px;
  }
`;
const OutlineButtonForJob = styled(Button)`
  font-size: 11px;
  color: ${(props) =>
    props.color ? props.color : CommonColor.primary_color} !important;
  border: 1px solid
    ${(props) => (props.border ? props.border : CommonColor.primary_color)} !important;
  background-color: ${(props) =>
    props.isActive ? props.border : CommonColor.white};
  border-radius: 10px;
  padding: 2px 15px;
  &:hover {
    background-color: ${(props) =>
      props.isActive ? props.border : CommonColor.light_grey}!important;
  }
`;
const AddIndustryBtn = styled(Button)`
  font-size: 11px;
  color: ${(props) =>
    props.color ? props.color : CommonColor.primary_color} !important;
  border: 1px solid
    ${(props) => (props.border ? props.border : CommonColor.primary_color)} !important;
  background-color: ${(props) =>
    props.isActive ? props.border : CommonColor.white};
  border-radius: 4px;
  padding: 1px 5px;
  min-width: auto;
  line-height: initial;
  position: absolute;
  top: 26px;
  right: 25px;
  &:hover {
    background-color: ${(props) =>
      props.isActive ? props.border : CommonColor.light_grey}!important;
  }
`;
const blink = keyframes`
 50% {
    opacity: 0.5;
  }
`;
const BlinkText = styled.div`
  animation: ${blink} 6s step-start infinite;
  background-color: ${CommonColor.bg_yellow};
  padding: 0 4px;
  border-radius: 2px;
`;
const JobMenuTab = styled.p`
  margin-left: 15px;
  font-size: 14px;
  color: ${(props) =>
    props.active ? CommonColor.secondary_color : CommonColor.primary_color};
  text-decoration: ${(props) => (props.active ? "underline" : "none")};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
  &:hover {
    color: ${CommonColor.secondary_color};
  }
`;
const TabMenuContainer = styled.div`
  position: relative;
`;
const TabSecondContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  span {
    margin-right: 15px;
  }
`;
const MultipleSignContainer = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-size: 10px;
    background-color: ${CommonColor.secondary_color};
    color: ${CommonColor.white};
    border-radius: 50px;
    padding: 2px 5px;
    font-weight: bold;
  }
`;
const CopyTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* z-index: 0; */
  /* margin-right: 20px; */
  /* width: 20px; */
  i {
    display: none;
  }
  :hover {
    /* margin-right: 0; */
    i {
      right: 10px;
      /* position: absolute; */
      display: block;
    }
  }
`;
export {
  CopyTextContainer,
  MultipleSignContainer,
  TabSecondContainer,
  TabMenuContainer,
  JobMenuTab,
  BlinkText,
  AddIndustryBtn,
  OutlineButtonForJob,
  FlexContainerForStatus,
  CustomButtonForJob,
  Title,
  ChipContainer,
  StatusContainer,
  JobFormContainer,
  AttachmentIcon,
  AttachmentName,
  AttachmentContainer,
  JobTab,
  JobTabContainer,
  // OutlineButtonForJob,
  JobViewDetailContainer,
  ConsultantTitle,
  ConsultantCard,
  // CustomButtonForJob,
  FlexContainer,
  AddButtonForJob,
  FlexContainerForFilter,
  CustomMenuContainer,
  CustomTab,
  List,
  ViewJobFormTitleContainer,
  ViewJobTitle,
  TitleContainer,
  DateTableInJob,
};
