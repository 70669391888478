import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import { CommonString } from "../Utils/CommonString";
import { FormControl } from "react-bootstrap";
import { Avatar } from "@material-ui/core";

const ApplicantRowTileContainer = styled.div`
  display: block;
  height: 84vh;
  margin: 0 5px;
  @media ${CommonString.mobile} {
    display: none;
    margin-left: 0px;
  }
`;
const ApplicantRowTileContainerForMobile = styled.div`
  display: none;
  @media ${CommonString.mobile} {
    display: block;
  }
`;
const ApplicantAddedByContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ApplicantAddedBy = styled.span`
  font-size: 8px;
  padding: 3px 10px;
  background: ${CommonColor.primary_bg};
  color: ${CommonColor.primary_color};
  border-radius: 5px;
  font-weight: 700;
  white-space: nowrap;
  margin-bottom: 5px;
  @media ${CommonString.mobile} {
    padding: 3px 5px;
  }
`;
const ApplicantViewDataContainer = styled.div`
  height: ${(props) => (props.isApplicantView ? "90vh" : "80vh")};
  overflow: auto;
`;
const IsVisibleContactDetail = styled.div`
  display: ${(props) => (props.isVisible === null ? "none" : "flex")};
`;
const IsVisibleLocation = styled.div`
  color: ${CommonColor.black_opacity_color};
  display: ${(props) =>
    props.data.zip === "" || props.data.state === "" || props.data.city === ""
      ? "none"
      : "block"};
`;
const IsVisibleSkill = styled.div`
  color: ${CommonColor.black_opacity_color};
  display: ${(props) => (props.data.skills === "" ? "none" : "block")};
`;
const IsVisibleComment = styled.div`
  color: ${CommonColor.black_opacity_color};
  display: ${(props) => (props.data.comment === "" ? "none" : "block")};
`;
const ApplicantViewFormTitle = styled.div`
  display: ${(props) => (props.isApplicantView ? "block" : "none")};
  margin: 24px;
  margin-bottom: 25px !important;
  margin-top: -8px;
`;
const SubmissionViewFormTitle = styled.div`
  display: ${(props) => (props.isApplicantView ? "block" : "none")};
  margin: 24px;
  margin-bottom: 25px !important;
  margin-top: -8px;
`;
const TextWithCopyIcon = styled.li`
  width: auto;
  display: flex;
  align-items: center;
  margin: 2px;
  list-style-type: circle;
  text-transform: ${(props) => (props.email ? "lowercase" : "capitalize")};
  color: ${CommonColor.black_opacity_color};
  svg {
    border: 0.5px solid ${CommonColor.primary_color_opacity};
    border-radius: 5px;
    box-shadow: 5px 5px 5px #efefef;
    cursor: pointer;
    margin-left: 10px;
    padding: 3px;
    color: ${CommonColor.primary_color};
    font-size: 20px;
  }
`;
const PdfContainer = styled.span`
  margin-top: 7px;
  width: 100%;
  height: 78vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid ${CommonColor.primary_color};
  border-style: dashed;
  &:hover {
    background-color: #14213010;
    cursor: pointer;
  }
`;
const RowTileContainer = styled.div`
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
  border: ${(props) =>
    props.isActive
      ? "1px solid" + CommonColor.primary_color
      : "1px solid " + CommonColor.light_grey};
`;
const RowTileTitle = styled.p`
  color: ${CommonColor.primary_color};
  font-weight: bold;
  text-transform: span {
    // font-weight: 500;
    color: ${CommonColor.grey};
  }
`;

const MainContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  overscroll-behavior-y: none;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 8px #888888;
  input {
    padding: 3px !important;
  }
  hr {
    margin: 2px !important;
  }
`;

const SearchMainContainer = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 13px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

const ListContainer = styled.div`
  width: 40vw;
  height: 87vh;
  margin: 10px;
  // padding-bottom: 80px;
  margin-top: 8px;
  overflow-y: scroll;
  scrollbar-width: thin;
  // &::-webkit-scrollbar {
  //   width: 0px;
  //   border: 0px solid black;
  // }
  // ::-webkit-scrollbar-track {
  //   background: transparent;
  // }
`;

const DocContainer = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  /* background-color: red; */
  overflow-y: scroll;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0px;
    border: 0px solid black;
  }
  ::-webkit-scrollbar-track {
  }
`;

const HorizontalView = styled.div`
  display: flex;
  width: 70vw;
`;

const PositionIcon = styled(Avatar)`
  position: absolute;
  right: 0;
  top: 0;
  background-color: red;
`;

const DocView = styled.iframe``;

const Visible = styled.div`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
`;

const VisiblePositionedDiv = styled.div`
  display: ${(props) => (props.isVisible ? "inherit" : "hidden")};
  position: absolute;
  bottom: 50;
  left: 3vw;
  z-index: 10000;
`;

const Input = styled.input`
  width: 80%;
  margin: 8px 0;
  padding-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  letter-spacing: 0.5px;
`;

const SearchButton = styled.button`
  border: none;
  padding: 2px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  letter-spacing: 1.8px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid ${CommonColor.primary_color};
  border-radius: 4px;
  background-color: ${(props) =>
    props.outline ? CommonColor.white : CommonColor.primary_color};
  color: ${(props) =>
    props.outline ? CommonColor.primary_color : CommonColor.white};
  font-weight: 600;
  &:hover {
    background: ${CommonColor.button_hover};
  }
`;
const SubmitButton = styled(SearchButton)`
  padding: 4px 28px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  // width: 22.5vw;
  // position: absolute;
  // z-index: 1;
  &:hover {
    background: ${CommonColor.button_hover};
  }
`;

const SearchInputForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  input {
    margin: 2px 0;
    &:focus-visible {
      border: none;
    }
  }
  &:hover {
    box-shadow: 0px 15px 10px -15px #cacaca96;
  }
  hr {
    margin: 0px !important;
  }
`;
const ApplicantButtonContainer = styled.div`
  margin-left: 10px;
  display: ${(props) => (props.isVisible ? "inherit" : "none")};
  // position: absolute;
  // bottom: 40px;
  // left: 10vw;
`;
const ApplicantLoaderContainer = styled.div`
  z-index: 10000;
  visibility: ${(props) => (props.isSearchLoading ? "visible" : "hidden")};
`;

const Content = styled.p`
  color: ${CommonColor.primary_color};
  font-weight: bold;
  font-size: 14px;
  text-transform: span {
    // font-weight: 500;
    color: ${CommonColor.grey};
  }
`;
const FlexContainerForDragFile = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ApplicantDataContainer = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid lightgrey;
  margin: 10px;
  border-radius: 5px;
  height: 88vh;
  overflow: auto;
`;
const SearchLoaderContainer = styled.div`
  position: absolute;
  bottom: 100;
  left: 3vw;
  z-index: 10000;
  visibility: ${(props) => (props.isSearchLoading ? "visible" : "hidden")};
`;
export {
  SearchLoaderContainer,
  ApplicantDataContainer,
  SearchMainContainer,
  FlexContainerForDragFile,
  ApplicantLoaderContainer,
  ApplicantButtonContainer,
  SearchInputForm,
  RowTileTitle,
  RowTileContainer,
  PdfContainer,
  TextWithCopyIcon,
  SubmissionViewFormTitle,
  ApplicantViewFormTitle,
  IsVisibleSkill,
  IsVisibleComment,
  IsVisibleLocation,
  IsVisibleContactDetail,
  ApplicantViewDataContainer,
  ApplicantRowTileContainer,
  ApplicantRowTileContainerForMobile,
  ApplicantAddedByContainer,
  ApplicantAddedBy,
  MainContainer,
  Body,
  SearchContainer,
  ListContainer,
  DocContainer,
  HorizontalView,
  PositionIcon,
  Input,
  SearchButton,
  DocView,
  Visible,
  VisiblePositionedDiv,
  SubmitButton,
  Content,
};
