import React from "react";
import Loader from "react-js-loader";
import { CommonColor } from "../../Utils/color";
import { SPINNER_LOADER_TYPE } from "../../Utils/constant";
import {
  FullScreenLoaderContainer,
  FullScreenLoaderMainContainer,
} from "../../style/common.style";
export default function FullScreenLoader(props) {
  return (
    <FullScreenLoaderMainContainer>
      <FullScreenLoaderContainer
        style={{ height: props.height, width: props.width }}
      >
        <Loader
          type={SPINNER_LOADER_TYPE}
          bgColor={CommonColor.primary_color}
          size={50}
          color={CommonColor.white}
          timeout={3000}
        />
      </FullScreenLoaderContainer>
    </FullScreenLoaderMainContainer>
  );
}
