import React, { useEffect, useRef, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { getClientName, jobStatusColor } from "../../../Utils/utilitiFunction";
import { SELECTE_PARTNER, VIEW_JOBS } from "../../../Utils/enum";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomTableContainer,
  TableTh,
  TableTd,
  TableRow,
  Table,
} from "../../../style/table.styled";
import {
  DATE_FORMATE,
  JOB_FOR_APPLICANT_TH,
  SUBMISSION_STATUS,
} from "../../../Utils/constant";
import moment from "moment";
import { CommonButtonName, CommonString } from "../../../Utils/CommonString";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { CommonColor } from "../../../Utils/color";
import JobController from "../../../controller/JobController";
import {
  checkNullString,
  checkNullValidation,
} from "../../../Utils/validation";
import { setSelectedJobForLockApplicant } from "../../../feature/store/JobReduxStore";
import JobDataShimmer from "../../Applicant/JobDataShimmer";
import ApplicantContoller from "../../../controller/ApplicantContoller";
import Container from "../../CommonComponet/Container";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";
import JobCustomController from "../../../controller/JobCustomController";
import { Icon } from "semantic-ui-react";
import { LockButtonContainer } from "../../../style/common.style";
import CustomCommonButton from "../../CommonComponet/CustomCommonButton";
import { SELECTE_JOB } from "../../../Utils/alertMessage";
import JobSearchForLockApplicant from "../../Applicant/JobSearchForLockApplicant";
import FullScreenLoader from "../../CommonComponet/FullScreenLoader";

export default function JobTableForViewApplicant(props) {
  const { onSubmit } = props;
  const listInnerRef = useRef();
  const dispatch = useDispatch();
  const jobController = new JobController();
  const applicantController = new ApplicantContoller();
  // const [searchResult, setSearchResult] = useState([]);
  // const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const selectedJobForLockApplicant = useSelector(
    (state) => state.JobReduxStore.selectedJobForLockApplicant
  );
  const is_loading_job = useSelector(
    (state) => state.ApplicantStore.is_loading_job
  );
  const selected_recruiter_id = useSelector(
    (state) => state.JobReduxStore.selected_recruiter_id
  );
  function getDate(date) {
    if (date === "") return "open";
    return moment(date).format(DATE_FORMATE);
  }
  const { getRecruiterName } = JobCustomController();
  useEffect(() => {
    getJobListForLockApplicant();
  }, [selected_recruiter_id]);

  async function getJobListForLockApplicant() {
    setIsLoading(true);
    var res = await applicantController.jobListForLockApplicant();
    setJobList(res);
    setIsLoading(false);
    // console.log(res);
  }
  async function onSearchByClientId(e, search_query) {
    e.preventDefault();
    setSearchQuery(e);
    setIsLoading(true);
    var sentData = {
      search_query: search_query.trim(),
    };
    var res = await jobController.searchByClientID(sentData);
    setSearchResult(res.data.data);
    setIsLoading(false);
    setIsSearchActive(false);
  }
  function getData() {
    if (searchQuery.length === 0) {
      return jobList;
    }
    return searchResult;
  }
  function onClickLock() {
    if (checkNullValidation(selectedJobForLockApplicant)) {
      alert.show(SELECTE_JOB, { type: CommonString.info });
      // setisLoading(false);
      return;
    }
    props.onViewModal(SELECTE_PARTNER);
  }

  return (
    <div>
      <JobSearchForLockApplicant
        onSubmit={(e) => onSearchByClientId(e, searchQuery)}
        onChange={(e) => {
          setIsSearchActive(true);
          setSearchQuery(e.target.value);
        }}
        isLoading={isLoading}
      />
      <CustomTableContainer
        style={{ paddingBottom: 70 }}
        ref={listInnerRef}
        height={"auto"}
      >
        {getData().length == 0 && (is_loading_job || isLoading) ? (
          <FullScreenLoader />
        ) : (
          <Table>
            <TableRow>
              {JOB_FOR_APPLICANT_TH.map((singleTH) => (
                <TableTh>
                  <span style={{ fontSize: 11 }}>{singleTH}</span>
                </TableTh>
              ))}
            </TableRow>
            {getData().map((data, index) => (
              <TableRow
                onDoubleClick={() => props.onViewModal(VIEW_JOBS, data)}
                onClick={() =>
                  window.innerWidth < 500
                    ? props.onViewModal(VIEW_JOBS, data)
                    : null
                }
                key={index}
              >
                <TableTd>
                  <Checkbox
                    size="small"
                    defaultChecked={
                      selectedJobForLockApplicant != null &&
                      selectedJobForLockApplicant._id === data._id
                    }
                    checked={
                      selectedJobForLockApplicant != null &&
                      selectedJobForLockApplicant._id === data._id
                    }
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => {
                      dispatch(setSelectedJobForLockApplicant(data));
                    }}
                    style={{ color: CommonColor.primary_color, padding: 0 }}
                  />
                </TableTd>
                <TableTd
                  style={{
                    color:
                      jobStatusColor[
                        data ? data.status : CommonString.all_uppercase
                      ].color,
                  }}
                >
                  <CustomizedTooltip
                    name={checkNullString(data?.client_position_id)}
                    title={checkNullString(data?.client_position_id)}
                  />
                </TableTd>
                <TableTd>
                  <CustomizedTooltip
                    name={getClientName(data?.partner_name)}
                    title={getClientName(data?.partner_name)}
                  />
                </TableTd>
                <TableTd>
                  <CustomizedTooltip
                    name={getClientName(data?.client_name)}
                    title={getClientName(data?.client_name)}
                  />
                </TableTd>
                <TableTd>
                  <CustomizedTooltip
                    name={data?.job_title}
                    title={data?.job_title}
                  />
                </TableTd>
                <TableTd isCenter={true}>{data?.no_of_submission}</TableTd>
                <TableTd isCenter={true}>
                  {checkNullString(data?.max_submission)}
                </TableTd>
                <TableTd>{getDate(data?.due_date)}</TableTd>
                <TableTd>{getRecruiterName(data)}</TableTd>
                <TableTd
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onViewModal(VIEW_JOBS, data);
                  }}
                >
                  <Icon icon={CommonIcon.dash_icon} />
                </TableTd>
              </TableRow>
            ))}
          </Table>
        )}
        <LockButtonContainer
          display={getData().length == 0 && (is_loading_job || isLoading)}
        >
          <CustomCommonButton
            display={true}
            style={{ marginRight: 10 }}
            basic
            onClick={() => onSubmit(SUBMISSION_STATUS.IN_TOUCH)}
            name={CommonButtonName.in_touch}
          />
          <CustomCommonButton
            display={true}
            onClick={() => onClickLock()}
            name={CommonButtonName.lock_profile}
          />
        </LockButtonContainer>
      </CustomTableContainer>
      <Container
        isLoading={isLoading}
        isVisible={!isLoading && jobList.length == 0}
      />
    </div>
  );
}
