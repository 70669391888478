import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { placeholder_name_without_id } from "../../Utils/mockData";
import { ErrorText } from "../../style/common.style";
import { Dropdown } from "semantic-ui-react";
export default function SelectTextFields(props) {
  const [inputValue, setInputValue] = useState(
    props.value ? props.getOptionLabel(props.value) : ""
  );
  function getValue(data) {
    if (data === null) {
      return;
    }
    if (placeholder_name_without_id.includes(props.placeholder)) {
      props.onChange(data);
      return;
    }
    let filteredData = props.options.filter(
      (singleData) => props.getOptionLabel(singleData) === data
    );
    props.onChange(filteredData[0]);
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const highlightedOption = props.options.find(
        (option) => props.getOptionLabel(option) === inputValue
      );
      if (highlightedOption === null) {
        return;
      }
      if (highlightedOption) {
        getValue(highlightedOption);
      }
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredOptions, setFilteredOptions] = useState(props?.options);
  // console.log("filteredOptions", filteredOptions, props?.options);
  // Handle search change to filter options
  const handleSearchChange = (e, { searchQuery }) => {
    setSearchTerm(searchQuery);
    const filtered = props?.options.filter((option) =>
      option.text.toLowerCase().includes(searchQuery.toLowerCase())
    );
    // setFilteredOptions(filtered);
  };
  const options = props?.options.map((item) => ({
    key: item._id === undefined ? item : item._id, // Unique identifier
    value: item?.value?.name !== undefined ? item?.value?.name : item, // Set entire object as value
    text: item.name === undefined ? item : item.name, // Display name in dropdown
  }));

  return (
    <div class="field">
      <label>{props.placeholder}</label>
      <Dropdown
        className="custom-dropdown"
        placeholder={props.placeholder}
        fluid
        search
        selection
        value={props.value}
        options={options}
        onChange={(e, { value }) => getValue(props.getOptionLabel(value))} // Update search term on selection
      />
      {props.error === undefined ? "" : <ErrorText>{props.error}</ErrorText>}
    </div>
  );
}
{
  /* <Autocomplete
        key={props?.value}
        inputValue={props?.value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onKeyDown={handleKeyDown}
        disablePortal
        onChange={(event, newValue) => {
          setInputValue(props.getOptionLabel(newValue));
          getValue(props.getOptionLabel(newValue));
        }}
        
        value={props?.value}
        options={props?.options}
        getOptionLabel={props.getOptionLabel}
        onBlur={props.onBlur}
        renderInput={(params) => <TextField {...params} />}
      /> */
}
