import React from "react";
import DashBoardView from "./DashBoardView";
import { Tab, TabPane } from "semantic-ui-react";
import DailyDashboardReport from "./DailyDashboardReport";

export default function DashboardMainView(props) {
  const { isLoading, DASHBOARD, onClick } = props;
  const panes = [
    {
      menuItem: "My Dashboard",
      render: () => (
        <DashBoardView
          isLoading={isLoading}
          status={DASHBOARD}
          onClick={(e) => onClick(e)}
        />
      ),
    },
    { menuItem: "Daily Reports", render: () => <DailyDashboardReport /> },
  ];
  return (
      <Tab panes={panes} menu={{ pointing: true, attached: false }} />
  );
}
