import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import FormInput from "../CommonComponet/FormInput";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import {
  WORK_AUTHORITY,
  TEXT_TERM,
  SOURCE_BY,
  TOTAL_EXPERIENCE,
} from "../../Utils/constant";
import CustomButton from "../CommonComponet/Button";
import {
  ADD_APPLICANT_ERROR,
  ADD_APPLICANT_SUCCESS,
  APPLICANT_CHECK_EXIST_ERROR,
  RESUME_ERROR,
  UPDATE_APPLICANT_ERROR,
  UPDATE_APPLICANT_SUCCESS,
} from "../../Utils/alertMessage";
import { useAlert } from "react-alert";
import { formatPhoneNumber, ValidateEmail } from "../../services/Validate";
import {
  ButtonName,
  CommonString,
  PlaceholderName,
} from "../../Utils/CommonString";
import ApplicatContoller from "../../controller/ApplicantContoller";
import { ErrorText } from "../../style/common.style";
import { useDispatch, useSelector } from "react-redux";
import {
  changeEmailList,
  changeIsOpenModal,
  changePhone,
  changeSelectedFileName,
  setApplicantData,
  updateApplicantData,
} from "../../feature/store/ApplicantStore";
import ApplicantCustomController from "../Applicant/ApplicantCustomController";
const axios = require("axios");

export default function AddApplicantForm(props) {
  const alert = useAlert();
  const { onClickApplicant } = ApplicantCustomController();
  const applicantList = useSelector((state) => state.ApplicantStore.applicants);
  const email = useSelector((state) => state.ApplicantStore.email);
  const phone = useSelector((state) => state.ApplicantStore.phone);
  const selected_file = useSelector(
    (state) => state.ApplicantStore.selected_file
  );
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  const resume_url = useSelector((state) => state.ApplicantStore.resume_url);
  const applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  const dispatch = useDispatch();
  const applicatContoller = new ApplicatContoller();
  const [workAuthority, setWorkAuthority] = useState();
  const [otherwWorkAuthority, setotherwWorkAuthority] = useState();
  const [taxTerm, SetTaxTerm] = useState();
  const [firstName, setFirstName] = useState();
  const [middleName, setMiddleName] = useState();
  const [lastName, setLastName] = useState();
  const [sourceBy, setSourceBy] = useState();
  const [city, setCity] = useState(" ");
  const [state, setState] = useState(" ");
  const [zip, setZip] = useState();
  const [secondaryEmail, setSecondaryEmail] = useState();
  const [secondaryphone, setSecondaryPhone] = useState();
  const [comment, setComment] = useState();
  const [pay_rate, setPay_rate] = useState();
  const [experience, setExperience] = useState();
  const [skills, setSkills] = useState();
  const [designation, setDesignation] = useState();
  const [otherTaxTearm, setOtherTaxTearm] = useState();
  const [ERROR_NAME, setERROR_NAME] = useState();
  const [corporation_deatils, setCorporation_deatils] = useState("");
  const [otherSourceFrom, setotherSourceFrom] = useState("");
  const [isExistError, setIsExitsError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onPrefilledData();
  }, []);

  function setValue(e) {
    setZip(e);
    var url = `https://maps.googleapis.com/maps/api/geocode/json?address=${e}&sensor=true&key=AIzaSyDAao27BmwZGdJfW9CtmsHamKrZFUYlC-4`;

    if (e.length !== 5) return;
    axios
      .get(url)
      .then(function (response) {
        if (response == null) return;
        // handle success
        var address = response.data.results[0];
        for (
          let index = 0;
          index < address.address_components.length;
          index++
        ) {
          const element = address.address_components[index];
          if (element.types.includes("locality")) {
            setCity(element.long_name);
          }

          if (element.types.includes("administrative_area_level_1")) {
            setState(element.long_name + " ( " + element.short_name + " ) ");
          }
        }
        // setState(response.data.results[0].formatted_address)
        // setCity(response.data.results[0].formatted_address)
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  }
  async function checkExist() {
    if (button_name === ButtonName.edit_applicant) return false;
    var sendData = {
      email: email,
    };
    var res = await applicatContoller.checkApplicantExist(sendData);
    if (res.is_exist) {
      setIsExitsError(true);
      return true;
    }
    return false;
  }
  function getFullName() {
    return (
      (firstName ?? "") +
      " " +
      (middleName ? middleName + " " : "") +
      (lastName ?? "")
    );
  }
  async function onAddApplicant(e) {
    setIsLoading(true);
    let resumeURL = "";
    if (button_name === ButtonName.edit_applicant) {
      resumeURL = props.data.resume_link;
    }
    if (
      (selected_file === null || selected_file === undefined) &&
      button_name !== ButtonName.edit_applicant
    ) {
      alert.show(RESUME_ERROR, { type: CommonString.info });
      setIsLoading(false);
      return;
    }
    if (!validate()) {
      setIsLoading(false);
      return;
    }
    if (await checkExist()) {
      alert.show("This user is already exist.", { type: CommonString.info });
      setIsExitsError(true);
      setIsLoading(false);
      return;
    }
    setIsExitsError(false);
    if (resumeURL === undefined || resumeURL === null) {
      alert.show(RESUME_ERROR, { type: CommonString.info });
      setIsLoading(false);
      return;
    }
    var user = localStorage.getItem("USER");
    var primaryPhoneNo =
      phone !== undefined ? phone.replace(/[^\d\+]/g, "") : "";
    var data = {
      first_name: firstName,
      middle_name: middleName ?? "",
      full_name: getFullName(),
      last_name: lastName ?? "",
      primary_email: email,
      secondary_email: secondaryEmail ?? "",
      secondary_phone_number: secondaryphone ?? "",
      city: city ?? "",
      state: state ?? "",
      zip: zip ?? "",
      phone: primaryPhoneNo ?? "",
      resume_link: resume_url,
      raw_resume:
        props.raw_resume === undefined
          ? props.data.raw_resume
          : props.raw_resume,
      source_by: sourceBy === "Others" ? otherSourceFrom : sourceBy ?? "",
      added_by: JSON.parse(user).user.name ?? "",
      added_by_user_id: JSON.parse(user).user._id ?? "",
      skills: skills ?? "",
      tax_term: taxTerm === "OTHERS" ? otherTaxTearm : taxTerm ?? "",
      designation: designation ?? "",
      experience: experience ?? "",
      payrate: pay_rate ?? "",
      comment: comment ?? "--",
      work_authority:
        workAuthority === "OTHERS" ? otherwWorkAuthority : workAuthority ?? "",
      corporation_deatils: corporation_deatils ?? "",
      other_source_from: otherSourceFrom,
    };
    if (button_name === ButtonName.edit_applicant) {
      var sendData = {
        _id: applicant._id,
        ...data,
      };
      // dispatch(changeIsLoadingInModal(true));
      var res = await applicatContoller.updateApplicant(sendData);
      if (res.data.status === 200) {
        dispatch(changeSelectedFileName(null));
        dispatch(updateApplicantData(sendData));
        dispatch(changeIsOpenModal(false));
        // dispatch(changeIsLoadingInModal(false));
        alert.show(UPDATE_APPLICANT_SUCCESS, { type: CommonString.success });
        setIsLoading(false);
      } else {
        // dispatch(changeIsLoadingInModal(false));
        alert.show(UPDATE_APPLICANT_ERROR, { type: CommonString.error });
        setIsLoading(false);
      }
      return;
    }
    // dispatch(changeIsLoadingInModal(true));
    var add_res = await applicatContoller.addApplicant(data);
    if (add_res.data.status === 200) {
      dispatch(changeSelectedFileName(null));
      dispatch(
        setApplicantData({
          data: [add_res.data.data, ...applicantList],
          page: 1,
        })
      );
      onClickApplicant(add_res.data.data);
      dispatch(changeIsOpenModal(false));
      // dispatch(changeIsLoadingInModal(false));
      alert.show(ADD_APPLICANT_SUCCESS, { type: CommonString.success });
      dispatch(changeSelectedFileName(null));
      setIsLoading(false);
    } else {
      // dispatch(changeIsLoadingInModal(false));
      alert.show(ADD_APPLICANT_ERROR, { type: CommonString.error });
      setIsLoading(false);
    }
  }

  function onPrefilledData() {
    if (button_name === ButtonName.edit_applicant && props.data != null) {
      setFirstName(props.data.first_name);
      setMiddleName(props.data.middle_name);
      setLastName(props.data.last_name);
      setCity(props.data.city);
      setSourceBy(props.data.source_by);
      // dispatch(changeResumeUrl(props.data.resume_link));
      // setResumeLink(props.data.resume_link);
      setState(props.data.state);
      SetTaxTerm(props.data.tax_term);
      setWorkAuthority(props.data.work_authority);
      setotherwWorkAuthority(props.data.work_authority);
      setZip(props.data.zip);
      setSecondaryEmail(props.data.secondary_email);
      setSecondaryPhone(formatPhoneNumber(props.data.phone));
      setSkills(props.data.skills);
      setDesignation(props.data.designation);
      setExperience(props.data.experience);
      setPay_rate(props.data.payrate);
      setComment(props.data.comment);
      dispatch(changeEmailList(props.data.primary_email));
      dispatch(changePhone(props.data.phone));
    }
  }

  function validate() {
    if (firstName === "" || firstName === null || firstName === undefined) {
      setERROR_NAME("FIRST_NAME");
      return false;
    }
    if (
      email === "" ||
      email === null ||
      email === undefined ||
      !ValidateEmail(email)
    ) {
      setERROR_NAME("PRIMARY_EMAIL");
      return false;
    }
    if (
      designation === "" ||
      designation === null ||
      designation === undefined
    ) {
      setERROR_NAME("DESIGNATION");
      return false;
    }
    setERROR_NAME("");
    return true;
  }
  return (
    <>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={4}>
          <FormInput
            error={ERROR_NAME === "FIRST_NAME"}
            onChange={(e) => {
              setFirstName(e.target.value);
              setERROR_NAME("");
            }}
            value={firstName || ""}
            placeholder="First Name *"
          />
          <FormInput
            onChange={(e) => setValue(e.target.value)}
            value={zip}
            placeholder="Zip"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            onChange={(e) => setMiddleName(e.target.value)}
            value={middleName || ""}
            placeholder="Middle Name"
          />
          <FormInput
            disabled={false}
            onChange={(e) => setCity(e.target.value)}
            value={city || ""}
            placeholder="City"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            onChange={(e) => setLastName(e.target.value)}
            value={lastName || ""}
            placeholder="Last Name"
          />
          <FormInput
            disabled={true}
            onChange={(e) => setState(e.target.value)}
            value={state || ""}
            placeholder="State"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            placeholder="Primary Phone No."
            value={phone || ""}
            onChange={(e) =>
              dispatch(changePhone(formatPhoneNumber(e.target.value)))
            }
          />
          <FormInput
            error={ERROR_NAME === "PRIMARY_EMAIL"}
            onChange={(e) => {
              dispatch(changeEmailList(e.target.value));
              setERROR_NAME("");
            }}
            onBlur={checkExist}
            value={email || ""}
            placeholder="Primary Email *"
          />
          {isExistError === true ? (
            <ErrorText>{APPLICANT_CHECK_EXIST_ERROR}</ErrorText>
          ) : (
            <div />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            placeholder="Secondary Phone No."
            value={secondaryphone || ""}
            onChange={(e) =>
              setSecondaryPhone(formatPhoneNumber(e.target.value))
            }
          />
          <FormInput
            value={secondaryEmail || ""}
            onChange={(e) => setSecondaryEmail(e.target.value)}
            placeholder="Secondary Email"
            onBlur={checkExist}
          />
          {isExistError === true ? (
            <ErrorText>{APPLICANT_CHECK_EXIST_ERROR}</ErrorText>
          ) : (
            <div />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <FormInput
            error={ERROR_NAME === "DESIGNATION" ? "fill" : "" > 0}
            placeholder="Designation / Title *"
            value={designation || ""}
            onChange={(e) => {
              setDesignation(e.target.value);
              setERROR_NAME("");
            }}
          />
          <FormInput
            type="number"
            className="icon"
            onChange={(e) => setPay_rate(e.target.value)}
            value={pay_rate || ""}
            placeholder="Expected Pay Rate"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectTextFields
            placeholder={PlaceholderName.experience}
            value={experience || ""}
            defaultValue={experience}
            onChange={(e) => setExperience(e)}
            options={TOTAL_EXPERIENCE}
            getOptionLabel={(option) => (option === null ? "" : option)}
          />
          {props.isView ? (
            <FormInput
              value={taxTerm || ""}
              placeholder={PlaceholderName.tax_term}
            />
          ) : (
            <SelectTextFields
              placeholder={PlaceholderName.tax_term}
              value={taxTerm || ""}
              onChange={(e) => SetTaxTerm(e)}
              options={TEXT_TERM}
              getOptionLabel={(option) => (option === null ? "" : option)}
            />
          )}
          {taxTerm === "Others" ? (
            <FormInput
              onChange={(e) => SetTaxTerm(e.target.value)}
              value={otherTaxTearm || ""}
              placeholder="Enter Tax Term"
            />
          ) : (
            <div />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {props.isView ? (
            <FormInput
              value={otherwWorkAuthority || ""}
              placeholder={PlaceholderName.work_authorization}
            />
          ) : (
            <SelectTextFields
              placeholder={PlaceholderName.work_authorization}
              value={workAuthority || ""}
              defaultValue={workAuthority}
              onChange={(e) => setWorkAuthority(e)}
              options={WORK_AUTHORITY}
              getOptionLabel={(option) => (option === null ? "" : option)}
            />
          )}
          {workAuthority === "OTHERS" ? (
            <FormInput
              onChange={(e) => setotherwWorkAuthority(e.target.value)}
              value={otherwWorkAuthority || ""}
              placeholder="Enter Work Authorization"
            />
          ) : (
            <div />
          )}
          {props.isView ? (
            <FormInput
              placeholder={PlaceholderName.sourced_from}
              value={sourceBy || ""}
            />
          ) : (
            <SelectTextFields
              placeholder={PlaceholderName.sourced_from}
              value={sourceBy || ""}
              onChange={(e) => setSourceBy(e)}
              options={SOURCE_BY}
              getOptionLabel={(option) => (option === null ? "" : option)}
            />
          )}
          {sourceBy === "Others" ? (
            <FormInput
              placeholder="Other Sourced From"
              value={otherSourceFrom || ""}
              onChange={(e) => setotherSourceFrom(e.target.value)}
            />
          ) : (
            <div />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <FormInput
            multiline
            error={ERROR_NAME === "SKILLS" ? "fill" : "" > 0}
            placeholder="Skills"
            value={skills || ""}
            onChange={(e) => setSkills(e.target.value)}
          />
        </Grid>
      </Grid>
      <FormInput
        placeholder="Comment"
        multiline
        value={comment || ""}
        onChange={(e) => setComment(e.target.value)}
      />
      <CustomButton
        isLoading={isLoading}
        onClick={onAddApplicant}
        name={button_name === ButtonName.edit_applicant ? "Update" : "Add"}
      />
    </>
  );
}
