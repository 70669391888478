import React, { useEffect } from "react";
import { SubmissionTitle } from "../../../style/detail.style";
import { CommonTitle } from "../../../Utils/CommonString";
import {
  Table,
  TableTbody,
  TableTd,
  TableTh,
} from "../../../style/table.styled";
import { TableRow } from "semantic-ui-react";
import { VIEW_SUBMISSION } from "../../../Utils/enum";
import moment from "moment";
import { DATE_FORMATE } from "../../../Utils/constant";
import { submission_table_in_applicant } from "../../../Utils/mockData";
import ApplicantCustomController from "../../Applicant/ApplicantCustomController";
import BaseModal from "../../Modal/BaseModal";
import SubmissionViewDetails from "../SubmissionViewDetails";
import FullScreenLoader from "../../CommonComponet/FullScreenLoader";

export default function ConnectedRequisitionTable(props) {
  const {
    submission_data_for_applicant,
    onViewSubmissionDetail,
    selected_submission_data_for_applicant,
    is_open_submission_detail,
    onCloseSubmissionDetail,
    is_loading_submission_data,
    consultantRef,
  } = ApplicantCustomController();

  return (
    <div className="mt-2" ref={consultantRef}>
      <BaseModal
        isBig={true}
        open={is_open_submission_detail}
        formTitle={VIEW_SUBMISSION}
        handleClose={() => onCloseSubmissionDetail()}
      >
        <SubmissionViewDetails
          handleClose={() => onCloseSubmissionDetail()}
          data={selected_submission_data_for_applicant}
        />
      </BaseModal>
      {is_loading_submission_data ? (
        <FullScreenLoader />
      ) : submission_data_for_applicant?.length === 0 ? (
        <SubmissionTitle>{CommonTitle.no_job}</SubmissionTitle>
      ) : (
        <>
          <SubmissionTitle>{CommonTitle.connected_applicant}</SubmissionTitle>
          <Table>
            <TableTbody>
              <TableRow>
                {submission_table_in_applicant.map((data) => (
                  <TableTd textAlignLeft={true}>
                    <b>{data}</b>
                  </TableTd>
                ))}
              </TableRow>
              {submission_data_for_applicant?.map((data) => (
                <TableRow onDoubleClick={() => onViewSubmissionDetail(data)}>
                  <TableTd textAlignLeft={true}>{data?.position_title}</TableTd>
                  <TableTd>{data?.submitions_tax_term}</TableTd>
                  <TableTd>${data?.submitions_payrate}</TableTd>
                  <TableTd>{data?.recruiter_name}</TableTd>
                  <TableTd>
                    {moment(data?._updated_at).format(DATE_FORMATE)}
                  </TableTd>
                  <TableTd textAlignLeft={true}>{data?.status}</TableTd>
                </TableRow>
              ))}
            </TableTbody>
          </Table>
        </>
      )}
    </div>
  );
}
