import React from "react";
import { Icon, Input } from "semantic-ui-react";

export default function JobSearchForLockApplicant(props) {
  const { isLoading, onSubmit } = props;

  return (
    <form style={{ paddingBottom: 10, paddingTop: 10 }} {...props}>
      <Input
        style={{ width: "100%" }}
        loading={isLoading}
        icon
        placeholder="Search..."
      >
        <input {...props} />
        <Icon onClick={onSubmit} name="search" />
      </Input>
    </form>
  );
}
