import { Grid } from "@mui/material";
import React from "react";
import { Body1, LightHeading } from "./TileStyle";
import moment from "moment";

export default function PersonalDetails(props) {
  const { data } = props;

  return data.length == 0 ? (
    <></>
  ) : (
    <Grid container>
       <Grid item md={3} paddingBottom={2}>
        <LightHeading>Measurement Date</LightHeading>
        <Body1>{moment(data[0].measurement_date).format("DD MMM YYYY")}</Body1>
      </Grid>
      <Grid item md={3} paddingBottom={2}>
        <LightHeading>Across Shoe Size</LightHeading>
        <Body1>{data[0].across_shoe_size}</Body1>
      </Grid>
      <Grid item md={3} paddingBottom={2}>
        <LightHeading>Chest</LightHeading>
        <Body1>{data[0].chest}</Body1>
      </Grid>
      <Grid item md={3} paddingBottom={2}>
        <LightHeading>Height</LightHeading>
        <Body1>{data[0].height}</Body1>
      </Grid>
     
      <Grid item md={3} paddingBottom={2}>
        <LightHeading>Shoe Size</LightHeading>
        <Body1>{data[0].shoe_size}</Body1>
      </Grid>
      <Grid item md={3} paddingBottom={2}>
        <LightHeading>Shoulder</LightHeading>
        <Body1>{data[0].shoulder}</Body1>
      </Grid>
      <Grid item md={3} paddingBottom={2}>
        <LightHeading>Waist</LightHeading>
        <Body1>{data[0].waist}</Body1>
      </Grid>
      <Grid item md={3} paddingBottom={2}>
        <LightHeading>Weight</LightHeading>
        <Body1>{data[0].weight}</Body1>
      </Grid>
      <Grid item md={12} paddingBottom={2}>
        <LightHeading>Comments</LightHeading>
        <Body1>{data[0].comments}</Body1>
      </Grid>
    </Grid>
  );
}
