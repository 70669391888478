import React from "react";
import {
  ApplicantButtonContainer,
  Input,
  SearchButton,
  SearchContainer,
  SearchInputForm,
  SearchMainContainer,
  SubmitButton,
} from "../../style/applicant.style";
import { ButtonName, CommonString } from "../../Utils/CommonString";
import { Divider } from "@material-ui/core";
import ApplicantCustomController from "./ApplicantCustomController";

export default function SearchApplicant() {
  const {
    onSearch,
    onChangeSearch,
    onClickOnAddButton,
    access_data_by_user_id,
    search_text,
  } = ApplicantCustomController();

  return (
    <SearchContainer>
      <SearchInputForm onSubmit={(e) => onSearch(e, 1)}>
        <Input
          type="text"
          placeholder={CommonString.keyString}
          // value={keyStringSearchText}
          onChange={(e) =>
            onChangeSearch("keyStringSearchText", e.target.value)
          }
        />
      </SearchInputForm>
      <Divider />
      <SearchInputForm onSubmit={(e) => onSearch(e, 1)}>
        <Input
          type="text"
          placeholder={CommonString.search_name}
          // value={nameSearchText}
          onChange={(e) => onChangeSearch("nameSearchText", e.target.value)}
        />
      </SearchInputForm>
      <SearchMainContainer>
        <SearchButton
          onClick={(e) => onSearch(e, 1)}
          outline={true}
          type="submit"
        >
          search
        </SearchButton>
        <ApplicantButtonContainer
          isVisible={access_data_by_user_id.includes("applicant_create")}
        >
          <SubmitButton onClick={(e) => onClickOnAddButton(e)}>
            {ButtonName.add_applicant}
          </SubmitButton>
        </ApplicantButtonContainer>
      </SearchMainContainer>
    </SearchContainer>
  );
}
