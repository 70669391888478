import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CommonColor } from "../../Utils/color";
import Switch from "@mui/material/Switch";

export default function CustomSwitch(props) {
  const theme = createMuiTheme({
    overrides: {
      MuiSwitch: {
        switchBase: {
          color: CommonColor.white,
        },
        colorSecondary: {
          "&$checked": {
            color: CommonColor.primary_color,
          },
        },
        track: {
          opacity: 0.2,
          backgroundColor: CommonColor.primary_color,
          "$checked$checked + &": {
            opacity: 0.7,
            backgroundColor: CommonColor.primary_color,
          },
        },
      },
    },
  });
  return <Switch size="small" {...props} />;
}
