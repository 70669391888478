import React, { useEffect, useRef, useState } from "react";
import {
  CommonStringForApplicant,
  CommonTitle,
} from "../../../Utils/CommonString";
import { CommonColor } from "../../../Utils/color";
import { formatPhoneNumber } from "../../../Utils/utilitiFunction";
import { Grid } from "@mui/material";
import { SubmissionController } from "../../../controller/SubmissionController";
import { useSelector } from "react-redux";
import { formatName, getUrl } from "../../../Utils/validation";
import ConnectedRequisitionTable from "./ConnectedRequisitionTable";
import TextWithIcon from "../../CommonComponet/TextWithIcon";
import { CommonIcon } from "../../../Utils/CommonIcon";
import ViewDetailTileForApplicant from "./ViewDetailTileForApplicant";
import {
  FlexContainerForFilter,
  SmallOrangeText,
} from "../../../style/common.style";
import CommentTable from "../../Applicant/CommentTable";
import CustomCommonButton from "../../CommonComponet/CustomCommonButton";
import ApplicantCustomController from "../../Applicant/ApplicantCustomController";

export default function ApplicantData(props) {
  const consultantRef = useRef(null);
  const { submission_data_for_applicant, getCityName, selected_applicant } =
    ApplicantCustomController();

  const scrollToAbout = () => {
    consultantRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleOpenPdf = (url) => {
    window.open(getUrl(url));
  };
  return (
    <>
      <div isApplicantView={props.isApplicantView}>
        {!props.isLockView ? (
          <>
            <FlexContainerForFilter>
              <h2>
                {formatName(selected_applicant?.first_name) +
                  " " +
                  formatName(selected_applicant?.middle_name) +
                  " " +
                  formatName(selected_applicant?.last_name)}
              </h2>
              <div className="flex">
                <CustomCommonButton
                  display={true}
                  style={{ marginRight: 10 }}
                  basic
                  color="orange"
                  onClick={() => handleOpenPdf(selected_applicant?.resume_link)}
                  name={`Close ${formatName(selected_applicant?.first_name)}`}
                />
                <CustomCommonButton
                  display={true}
                  onClick={() => handleOpenPdf(selected_applicant?.resume_link)}
                  name="View Resume"
                />
              </div>
            </FlexContainerForFilter>
            <TextWithIcon
              icon={CommonIcon.email}
              text={selected_applicant?.primary_email}
            />
            <TextWithIcon
              icon={CommonIcon.call}
              text={formatPhoneNumber(selected_applicant?.phone)}
            />
            <SmallOrangeText onClick={scrollToAbout}>
              {submission_data_for_applicant.length === 0
                ? CommonTitle.no_job
                : `${submission_data_for_applicant.length} ${CommonTitle.connected_consultant}`}
            </SmallOrangeText>
            <hr />
            <table
              style={{ display: "flex", flexWrap: "wrap", margin: "-5px" }}
            >
              <ViewDetailTileForApplicant
                color={CommonColor.secondary_color}
                name={CommonStringForApplicant.experience}
                value={selected_applicant?.experience}
              />
              <ViewDetailTileForApplicant
                color={CommonColor.secondary_color}
                name={CommonStringForApplicant.expected_pay_rate}
                value={"$" + selected_applicant?.payrate}
              />
              <ViewDetailTileForApplicant
                name={CommonStringForApplicant.tax_term}
                value={selected_applicant?.tax_term}
              />
              <ViewDetailTileForApplicant
                name={CommonStringForApplicant.work_authority}
                value={selected_applicant?.work_authority}
              />
              <ViewDetailTileForApplicant
                color={CommonColor.secondary_color}
                name={CommonStringForApplicant.added_by}
                value={selected_applicant?.added_by ?? ""}
              />
              <ViewDetailTileForApplicant
                name={CommonStringForApplicant.source_by}
                value={selected_applicant?.source_by ?? ""}
              />
            </table>
            <hr />
            <ViewDetailTileForApplicant
              name={CommonStringForApplicant.skills}
              value={selected_applicant?.skills}
            />
            <ViewDetailTileForApplicant
              name={CommonStringForApplicant.comment}
              value={selected_applicant?.comment}
            />
            {selected_applicant?.comments.length === 0 ? <></> : <hr />}
            <CommentTable data={selected_applicant} />
            <div ref={consultantRef}>
              <ConnectedRequisitionTable />
            </div>
            {/* <ApplicantDocument height={"79vh"} is_visible_icon={false} /> */}
          </>
        ) : (
          <Grid container>
            <Grid
              item
              md={3}
              style={{
                borderRight: "1px solid lightgrey",
                padding: 10,
              }}
            >
              <TextWithIcon
                big={true}
                icon={CommonIcon.user}
                text={
                  formatName(selected_applicant?.first_name) +
                  " " +
                  formatName(selected_applicant?.middle_name) +
                  " " +
                  formatName(selected_applicant?.last_name)
                }
              />
              <TextWithIcon
                icon={CommonIcon.bag}
                text={selected_applicant?.designation}
              />
              <TextWithIcon
                icon={CommonIcon.email}
                text={selected_applicant?.primary_email}
              />
              <TextWithIcon
                icon={CommonIcon.map}
                text={getCityName(selected_applicant)}
              />
              <TextWithIcon
                icon={CommonIcon.call}
                text={formatPhoneNumber(selected_applicant?.phone)}
              />
              <br />
            </Grid>
            <Grid
              item
              md={3}
              style={{ borderRight: "1px solid lightgrey", padding: 10 }}
            >
              <ViewDetailTileForApplicant
                color={CommonColor.secondary_color}
                name={CommonStringForApplicant.experience}
                value={selected_applicant?.experience}
              />
              <ViewDetailTileForApplicant
                name={CommonStringForApplicant.source_by}
                value={selected_applicant?.source_by ?? ""}
              />
              <ViewDetailTileForApplicant
                color={CommonColor.secondary_color}
                name={CommonStringForApplicant.added_by}
                value={selected_applicant?.added_by ?? ""}
              />
              <ViewDetailTileForApplicant
                name={CommonStringForApplicant.tax_term}
                value={selected_applicant?.tax_term}
              />
            </Grid>
            <Grid item md={5} style={{ padding: 10 }}>
              <ViewDetailTileForApplicant
                name={CommonStringForApplicant.comment}
                value={selected_applicant?.comment}
              />
              <ViewDetailTileForApplicant
                name={CommonStringForApplicant.work_authority}
                value={selected_applicant?.work_authority}
              />
              <ViewDetailTileForApplicant
                color={CommonColor.secondary_color}
                name={CommonStringForApplicant.expected_pay_rate}
                value={"$" + selected_applicant?.payrate}
              />
              <ViewDetailTileForApplicant
                name={CommonStringForApplicant.skills}
                value={selected_applicant?.skills}
              />
            </Grid>
            {/* <ApplicantDocument height={"79vh"} is_visible_icon={false} /> */}
          </Grid>
        )}
      </div>
    </>
  );
}
