import React from "react";
import {
  TextInTextIcon,
  TextWithIconContainer,
} from "../../style/common.style";
import { IconInTextIcon } from "../../style/Icon.style";

export default function TextWithIcon(props) {
  const { text, icon } = props;
  // console.log(props.text);
  return props.text === null || props.text === "" ? (
    <></>
  ) : (
    <TextWithIconContainer data={text}>
      <IconInTextIcon icon={icon} />
      <TextInTextIcon>{text} </TextInTextIcon>
    </TextWithIconContainer>
  );
}
