import React, { useEffect, useRef } from "react";
import {
  Body,
  ListContainer,
  MainContainer,
  ApplicantDataContainer,
  SearchLoaderContainer,
} from "../../style/applicant.style";
import { ButtonName } from "../../Utils/CommonString";
import { useDispatch, useSelector } from "react-redux";
import ApplicantList from "../Applicant/ApplicantList";
import SearchLoader from "../CommonComponet/SearchLoader";
import CommonBaseModal from "../Modal/CommonBaseModal";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import { getVisibility } from "../../feature/store/ReduxStore";
import NoData from "../../assets/images/nodata.png";
import { TableNoDataContainer } from "../../style/common.style";
import {
  ButtonForChangePage,
  ButtonForChangePageContainer,
} from "../../style/button.style";
import ApplicantCustomController from "../Applicant/ApplicantCustomController";
import ApplicantBasicData from "../Applicant/ApplicantBasicDetails";
import FullScreenLoader from "../CommonComponet/FullScreenLoader";
import SearchApplicant from "../Applicant/SearchApplicant";

export default function ApplicantsView() {
  const listInnerRef = useRef();
  const dispatch = useDispatch();
  const {
    intializationData,
    getList,
    isVisibleLoadMoreButton,
    is_data_null,
    getFormView,
    initial_loader,
    handleScroll,
    is_loading_for_search,
    selected_applicant,
    load_more_data,
  } = ApplicantCustomController();

  const pageNumber = useSelector((state) => state.ApplicantStore.pageNumber);
  const button_name = useSelector((state) => state.ApplicantStore.button_name);

  useEffect(() => {
    var isVisible = onCheckLevelAccessibility();
    dispatch(getVisibility(isVisible));
    intializationData(pageNumber);
  }, []);

  return (
    <MainContainer>
      <CommonBaseModal children={getFormView(button_name)} />
      <SearchApplicant />
      <Body>
        {initial_loader || is_loading_for_search ? (
          <FullScreenLoader />
        ) : is_data_null || !selected_applicant ? (
          <></>
        ) : (
          <>
            <ListContainer ref={listInnerRef}>
              <ApplicantList data={getList()}></ApplicantList>
              <ButtonForChangePageContainer
                isVisible={isVisibleLoadMoreButton}
                style={{ marginTop: 5 }}
              >
                <ButtonForChangePage onClick={() => handleScroll()}>
                  {load_more_data ? "Loading..." : ButtonName.load_more}
                </ButtonForChangePage>
              </ButtonForChangePageContainer>
              <SearchLoaderContainer>
                <SearchLoader is_applicant={true} />
              </SearchLoaderContainer>
            </ListContainer>
            <ApplicantDataContainer>
              <ApplicantBasicData isLockView={false} />
            </ApplicantDataContainer>
          </>
        )}
        {initial_loader ? <FullScreenLoader /> : <></>}
        <TableNoDataContainer
          isVisible={
            is_data_null && (!initial_loader || !is_loading_for_search)
          }
        >
          <img src={NoData} alt="" />
        </TableNoDataContainer>
      </Body>
    </MainContainer>
  );
}
