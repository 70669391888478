import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartnerTaxTermComponentForJob from "../jobs/PartnerTaxTermComponentForJob";
import PartnerDateComponent from "../jobs/PartnerDateComponent";
import CustomButton from "../CommonComponet/Button";
import JobController from "../../controller/JobController";
import { onchangeSelectedPartnerTaxList } from "../../feature/store/ClientReduxStore";

export default function AddPartnerInUpdateJob(props) {
  const dispatch = useDispatch();
  var jobController = new JobController();
  const selected_partner_tax_list = useSelector(
    (state) => state.ClientReduxStore.selected_partner_tax_list
  );

  useEffect(() => {
    jobController.onGetPArtner(props.data);
    // jobController.getPartnerByJobId({ job_id: props.data.job_id });
    dispatch(onchangeSelectedPartnerTaxList([]));
  }, []);

  function getRecruiterName() {
    var recruiterName = [];
    selected_partner_tax_list.map((data) =>
      recruiterName.push(data.recruiter_name)
    );
    return recruiterName;
  }

  // function getPartnerList(){
  //   var data = selected_partner_tax_list.filter((data)=>data)
  // }

  return (
    <div>
      <PartnerTaxTermComponentForJob
        preFieldData={props.preFieldData}
        isAddNewPartner={props.isAddNewPartner}
        isUpdate={false}
      />
      {selected_partner_tax_list.map((data) => (
        <PartnerDateComponent
          data={data}
          jobData={props.data !== null ? props.data.job : ""}
          childJob={props.data !== null ? props.data.child_job : ""}
          isUpdate={false}
          isAddNewPartner={true}
        />
      ))}
      <CustomButton
        onClick={() =>
          jobController.onAddNewPartner({
            ...props.data,
            recruiter_id: getRecruiterName(),
          })
        }
        name={"Add"}
      />
    </div>
  );
}
