import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  ButtonWithIcon,
  FlexContainerForSocialLinkForRecruiter,
  RecruiterBigText,
  RecruiterBigTextContainer,
  RecruiterIcon,
  RecruiterImg,
  RecruiterImgContainer,
  RecruiterReportContainer,
  RecruiterReportSpan,
  RecruiterReportText,
  RecruiterSocialIcon,
  RecruiterSpan,
  RecruiterTextContainer,
  RecruiterTitle,
} from "../../style/detail.style copy";
import { CommonIcon } from "../../Utils/CommonIcon";
import { CommonString } from "../../Utils/CommonString";
import { LONG_DATE_FORMATE, PROFILE_BASEURL } from "../../Utils/constant";
import ReportController from "../../controller/ReportController";
import User from "../../assets/images/User.png";
import RecruiterController from "../../controller/RecruiterController";
import {
  getAccessVisibility,
  getValueForAccessRights,
} from "../../Utils/utilitiFunction";
import { Title } from "../../style/common.style";
import { Grid } from "@mui/material";
import { FlexContainer } from "../../style/dice.style";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { Tab, TabPane } from "semantic-ui-react";
import UserRights from "./UserRights";
import PersonalDetails from "../Recruiter/PersonalDetails";
import ProfessionalDetails from "../Recruiter/ProfessionalDetails";

export default function RecruiterViewDetails(props) {
  const reportController = new ReportController();
  var recruiterController = new RecruiterController();
  const [submissionData, setSubmissionData] = useState();
  const [accessData, setAccessData] = useState([]);
  const [personalInfo, setPersonalInfo] = useState([]);
  const [bodyInfo, setBodyInfo] = useState([]);
  const user_data = useSelector((state) => state.counter.user_data);
  const panes = [
    {
      menuItem: "Professional Details",
      render: () => (
        <TabPane attached={false}>
          <ProfessionalDetails data={personalInfo} />
        </TabPane>
      ),
    },
    {
      menuItem: "Personal Details",
      render: () => (
        <TabPane attached={false}>
          <PersonalDetails data={bodyInfo} />
        </TabPane>
      ),
    },
    {
      menuItem: "ATS Rights",
      render: () => (
        <TabPane attached={false}>
          <UserRights
            accessData={accessData}
            onClick={() => props.onClick()}
            user_data={user_data}
          />
        </TabPane>
      ),
    },
  ];
  useEffect(() => {
    getReportData();
    getAccessById();
    getBodyInfo();
    getPersonalInfo();
  }, []);

  async function getBodyInfo() {
    try {
      var body_res = await recruiterController.getUserPersonalBodyInfo({
        user_id: props?.data?._id,
      });
      setBodyInfo(body_res.data.data)
    } catch (error) {
      console.log(error);
    }
  }
  async function getPersonalInfo() {

    try {
      var body_res = await recruiterController.getUserProfessionalInfo({
        user_id: props?.data?._id,
      });
      setPersonalInfo(body_res.data.data)
    } catch (error) {
      console.log(error);
    }
  }
  async function getAccessById() {
    var access_res = await recruiterController.getAccessById({
      user_id: props?.data?._id,
    });
    let access = getAccessVisibility(access_res);
    setAccessData(access);
  }

  async function getReportData() {
    var sendData = {
      recruiter_id: props.data._id,
      year: moment().year(),
    };
    var res_2 = await reportController.getYearSubmissionReport(sendData);
    setSubmissionData(res_2.data);
    return;
  }

  return (
    <>
      <RecruiterImgContainer>
        <RecruiterImg
          src={
            props.data.profile_image_url !== undefined
              ? PROFILE_BASEURL + props.data.profile_image_url
              : User
          }
        />
        <RecruiterTextContainer>
          <RecruiterTitle>
            {props.data.employee_id + " (" + props.data.designation + ")"}
          </RecruiterTitle>
          {/* <RecruiterText>{props.data.employee_id}</RecruiterText> */}

          <FlexContainerForSocialLinkForRecruiter>
            <RecruiterBigText href={CommonString.mail_link + props.data.email}>
              <RecruiterSocialIcon icon={CommonIcon.email} />
              {props.data.email}
            </RecruiterBigText>
            <RecruiterBigText href={CommonString.call_link + props.data.phone}>
              <RecruiterSocialIcon isMarginLeft={true} icon={CommonIcon.call} />
              {props.data.phone}
            </RecruiterBigText>
          </FlexContainerForSocialLinkForRecruiter>
          <RecruiterSpan>
            <RecruiterIcon icon={CommonIcon.cake} />
            {moment(props.data.birthday).format(LONG_DATE_FORMATE)}
          </RecruiterSpan>
        </RecruiterTextContainer>
      </RecruiterImgContainer>
      <hr />
      <RecruiterBigTextContainer>{props.data.about}</RecruiterBigTextContainer>
      {props.data.is_recruiter === 1 ? (
        <RecruiterReportContainer>
          <RecruiterReportText>
            <RecruiterReportSpan>
              {submissionData === undefined ? 0 : submissionData.SUBMISSION}
            </RecruiterReportSpan>
            {CommonString.submission}
          </RecruiterReportText>
          <RecruiterReportText>
            <RecruiterReportSpan>
              {submissionData === undefined ? 0 : submissionData.INTERVIEW}
            </RecruiterReportSpan>
            {CommonString.interview}
          </RecruiterReportText>
          <RecruiterReportText>
            <RecruiterReportSpan>
              {submissionData === undefined ? 0 : submissionData.OFFER}
            </RecruiterReportSpan>
            {CommonString.offer}
          </RecruiterReportText>
          <RecruiterReportText>
            <RecruiterReportSpan>
              {submissionData === undefined ? 0 : submissionData.PLACEMENT}
            </RecruiterReportSpan>
            {CommonString.placement}
          </RecruiterReportText>
        </RecruiterReportContainer>
      ) : (
        <div />
      )}
      <hr />

      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </>
  );
}
