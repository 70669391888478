import React from "react";
import { Tab } from "semantic-ui-react";
import ApplicantBasicData from "./ApplicantBasicDetails";
import ConnectedRequisitionTable from "../ViewDetails/view_component/ConnectedRequisitionTable";
import { TableMainContainer } from "../../style/table.styled";
import JobTableForViewApplicant from "../DataTable/Table/JobTableForViewApplicant";

export default function CloseApplicantMainView(props) {
  const { onViewModal, onSubmit } = props;
  const panes = [
    {
      menuItem: "Basic Details",
      render: () => (
        <div style={{ paddingTop: 10 }}>
          <ApplicantBasicData isLockView={true} />
        </div>
      ),
    },
    {
      menuItem: "Connected Consultant",
      render: () => <ConnectedRequisitionTable />,
    },
    {
      menuItem: "Open Positions",
      render: () => (
        <TableMainContainer>
          <JobTableForViewApplicant
            onSubmit={onSubmit}
            onViewModal={onViewModal}
          />
        </TableMainContainer>
      ),
    },
  ];

  return <Tab menu={{ pointing: true }} panes={panes} />;
}
