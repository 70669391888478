import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  JobtableForbillrate,
  JobtableForbillrateTh,
  TableRow,
} from "../../style/table.styled";
import FormInput from "../CommonComponet/FormInput";
import PatnerSelectionComponentForJob from "../CommonComponet/jobComponent/PartnerSelectionForJob";
import PayRateSectionForJob from "./PayRateSectionForJob";
import { TableBody } from "@mui/material";
import { onchangeSelectedPartnerTaxList } from "../../feature/store/ClientReduxStore";

export default function PartnerTaxTermComponentForJob(props) {
  const dispatch = useDispatch();
  const selected_partner_tax_list = useSelector(
    (state) => state.ClientReduxStore.selected_partner_tax_list
  );
  useEffect(() => {
    dispatch(onchangeSelectedPartnerTaxList([]));
  }, []);

  return (
    <div>
      {props.isUpdate ? (
        <div>
          <FormInput
            value={props.data !== undefined ? props.data.partner_name : ""}
            placeholder="Partner Name"
            disabled={true}
          />
        </div>
      ) : (
        <PatnerSelectionComponentForJob
          preFieldData={props.preFieldData}
          selectedValueClient={props.selectedValueClient}
          isAddNewPartner={props.isAddNewPartner}
        />
      )}
      {selected_partner_tax_list.length === 0 ? (
        <div />
      ) : (
        <JobtableForbillrate>
          <TableBody>
            <TableRow>
              <JobtableForbillrateTh>
                Partner and Assigned Recruiter
              </JobtableForbillrateTh>
              <JobtableForbillrateTh>1099</JobtableForbillrateTh>
              <JobtableForbillrateTh>W2 wo B</JobtableForbillrateTh>
              <JobtableForbillrateTh>W2 w B</JobtableForbillrateTh>
              <JobtableForbillrateTh>W2 Sal</JobtableForbillrateTh>
            </TableRow>
            <PayRateSectionForJob data={props.data} isUpdate={props.isUpdate} />
          </TableBody>
        </JobtableForbillrate>
      )}
    </div>
  );
}
