import React from "react";
import { CommonColor } from "../../Utils/color";
import { formatPhoneNumber } from "../../Utils/utilitiFunction";
import CustomCommonButton from "../CommonComponet/CustomCommonButton";
import {
  FlexContainerForFilter,
  SmallOrangeText,
} from "../../style/common.style";
import { formatName } from "../../Utils/validation";
import TextWithIcon from "../CommonComponet/TextWithIcon";
import { CommonIcon } from "../../Utils/CommonIcon";
import ViewDetailTileForApplicant from "../ViewDetails/view_component/ViewDetailTileForApplicant";
import {
  CommonStringForApplicant,
  CommonTitle,
} from "../../Utils/CommonString";
import ApplicantCustomController from "./ApplicantCustomController";
import CommentTable from "./CommentTable";
import ConnectedRequisitionTable from "../ViewDetails/view_component/ConnectedRequisitionTable";

export default function ApplicantBasicData(props) {
  const {
    selected_applicant,
    handleOpenPdf,
    submission_data_for_applicant,
    scrollToAbout,
    consultantRef,
    getFullName,
    onClickOnCloseButton,
  } = ApplicantCustomController();
  const { isLockView } = props;

  return (
    <>
      <FlexContainerForFilter>
        <h2>{getFullName(selected_applicant)}</h2>
        <div className="flex">
          <CustomCommonButton
            display={isLockView}
            style={{ marginRight: 10 }}
            basic
            color="orange"
            onClick={() => onClickOnCloseButton(selected_applicant)}
            name={`Close ${formatName(selected_applicant?.first_name)}`}
          />
          <CustomCommonButton
            display={true}
            onClick={() => handleOpenPdf(selected_applicant?.resume_link)}
            name="View Resume"
          />
        </div>
      </FlexContainerForFilter>
      <TextWithIcon
        icon={CommonIcon.email}
        text={selected_applicant?.primary_email}
      />
      <TextWithIcon
        icon={CommonIcon.call}
        text={formatPhoneNumber(selected_applicant?.phone)}
      />
      {isLockView ? (
        <></>
      ) : (
        <SmallOrangeText onClick={scrollToAbout}>
          {submission_data_for_applicant.length === 0
            ? CommonTitle.no_job
            : `${submission_data_for_applicant.length} ${CommonTitle.connected_consultant}`}
        </SmallOrangeText>
      )}
      <hr />
      <table style={{ display: "flex", flexWrap: "wrap", margin: "-5px" }}>
        <ViewDetailTileForApplicant
          color={CommonColor.secondary_color}
          name={CommonStringForApplicant.experience}
          value={selected_applicant?.experience}
        />
        <ViewDetailTileForApplicant
          color={CommonColor.secondary_color}
          name={CommonStringForApplicant.expected_pay_rate}
          value={"$" + selected_applicant?.payrate}
        />
        <ViewDetailTileForApplicant
          name={CommonStringForApplicant.tax_term}
          value={selected_applicant?.tax_term}
        />
        <ViewDetailTileForApplicant
          name={CommonStringForApplicant.work_authority}
          value={selected_applicant?.work_authority}
        />
        <ViewDetailTileForApplicant
          color={CommonColor.secondary_color}
          name={CommonStringForApplicant.added_by}
          value={selected_applicant?.added_by ?? ""}
        />
        <ViewDetailTileForApplicant
          name={CommonStringForApplicant.source_by}
          value={selected_applicant?.source_by ?? ""}
        />
      </table>
      {selected_applicant?.skills === "" ||
      selected_applicant?.comment === "" ? (
        <></>
      ) : (
        <hr />
      )}
      <ViewDetailTileForApplicant
        name={CommonStringForApplicant.skills}
        value={selected_applicant?.skills}
      />
      <ViewDetailTileForApplicant
        name={CommonStringForApplicant.comment}
        value={selected_applicant?.comment}
      />
      {selected_applicant?.comments?.length === 0 ? <></> : <hr />}
      <CommentTable data={selected_applicant} />
      {isLockView ? (
        <></>
      ) : (
        <div ref={consultantRef}>
          <ConnectedRequisitionTable />
        </div>
      )}
    </>
  );
}
