import React, { useEffect, useState } from "react";
import RowText from "../../ViewDetails/view_component/RowText";
import AddInterviewDetail from "../../Modal/AddInterviewDetail";
import { useSelector, useDispatch } from "react-redux";
import { SubmissionController } from "../../../controller/SubmissionController";
import { changeInterviewDetails } from "../../../feature/store/SubmissionReduxStore";
import { ADD_INTERVIEW_DETAIL } from "../../../Utils/enum";
import moment from "moment";
import { CommonStringForInterviewDetails } from "../../../Utils/CommonString";
import { DATE_FORMATE } from "../../../Utils/constant";
import { ViewDetailContainer } from "../../../style/common.style";
import { convertTo12HourFormat } from "../../../Utils/utilitiFunction";

export default function InterviewStatus(props) {
  const submissionController = new SubmissionController();
  const dispatch = useDispatch();
  const interview_details = useSelector(
    (state) => state.SubmissionReduxStore.interview_details
  );
  const selectedSubmissionData = useSelector(
    (state) => state.SubmissionReduxStore.selectedSubmissionData
  );
  const [isLoading, setisLoading] = useState(false.toString());

  useEffect(() => {
    getInterviewData();
  }, [selectedSubmissionData]);

  async function AddInterviewDetails() {
    props.isLoading(true);
    setTimeout(() => {
      props.isLoading(false);
      props.handleClose();
    }, 1000);
  }

  async function getInterviewData() {
    setisLoading(true.toString());
    var data = {
      _id: props.selectedData._id,
    };
    var res = await submissionController.getInterviewDetailById(data);
    if (res.status === 200) {
      setisLoading(false.toString());
      dispatch(changeInterviewDetails(res.data.data));
    }
    setisLoading(false.toString());
  }

  return props.modalStatus === ADD_INTERVIEW_DETAIL ? (
    <AddInterviewDetail
      AddInterviewDetails={(e) => AddInterviewDetails(e)}
      isLoading={props.isLoading}
      modalStatus={props.modalStatus}
      data={interview_details}
    />
  ) : (
    <ViewDetailContainer>
      <RowText
        isloading={isLoading}
        title={CommonStringForInterviewDetails.date}
        value={
          interview_details
            ? moment(interview_details.date).format(DATE_FORMATE)
            : ""
        }
      />
      <RowText
        isloading={isLoading}
        title={CommonStringForInterviewDetails.interview_mode}
        value={interview_details ? interview_details.interview_mode : ""}
      />
      <RowText
        isloading={isLoading}
        title={CommonStringForInterviewDetails.note}
        value={interview_details ? interview_details.note : ""}
      />
      <RowText
        isloading={isLoading}
        title={CommonStringForInterviewDetails.point_of_contact}
        value={interview_details ? interview_details.point_of_contact : ""}
      />
      <RowText
        isloading={isLoading}
        title={CommonStringForInterviewDetails.time}
        value={
          interview_details
            ? interview_details?.interview_time !== null
              ? `${convertTo12HourFormat(interview_details?.interview_time)} ${
                  interview_details?.time_zone
                }`
              : interview_details?.time
            : ""
        }
      />
      <RowText
        nodivider={true}
        isloading={isLoading}
        title={CommonStringForInterviewDetails.web_ex_link}
        value={interview_details ? interview_details.web_ex_link : ""}
      />
    </ViewDetailContainer>
  );
}
