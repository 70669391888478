import React from "react";
import { CommonIcon } from "../../Utils/CommonIcon";
import { ButtonWithIcon } from "../../style/detail.style copy";
import { FlexContainer } from "../../style/dice.style";
import { Title } from "../../style/common.style";
import { Grid, Icon } from "@mui/material";
import { getValueForAccessRights } from "../../Utils/utilitiFunction";

export default function UserRights({accessData,onClick,user_data}) {
  return accessData.length === 0 ? (
    <></>
  ) : (
    <>
      <FlexContainer>
        <Title>User Rights</Title>
        {console.log(user_data)}
        {user_data.level_id === "d7c33d40-ad7c-11ed-9f41-d1a5496dedfc" ? (
          <ButtonWithIcon onClick={() => onClick()}>
            <Icon icon={CommonIcon.modal_edit} />
            Edit
          </ButtonWithIcon>
        ) : (
          <></>
        )}
      </FlexContainer>
      <Grid container>
        {accessData.map((data) => (
          <Grid item md={2}>
            {getValueForAccessRights(data)}
          </Grid>
        ))}
      </Grid>
    </>
  );
}
