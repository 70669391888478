import React, { useEffect, useState } from "react";
import {
  CustomTableContainer,
  TableTbody,
  TableTd,
  TableTh,
  Table,
  TableRow,
  TableName,
  DashboardTableContainer,
} from "../../../style/table.styled";
import { DATE_FORMATE, SUBMISSION_TH } from "../../../Utils/constant";
import {
  ADD_INTERVIEW_DETAIL,
  INTERVIEW_DETAILS,
  VIEW_SUBMISSION,
} from "../../../Utils/enum";
import { CommonColor } from "../../../Utils/color";
import moment from "moment";
import {
  convertTo12HourFormat,
  getClientName,
  getFormTitleForSubmission,
  onCheckLevelAccessibilityForDashboard,
} from "../../../Utils/utilitiFunction";
import SubmissionViewDetails from "../../ViewDetails/SubmissionViewDetails";
import BaseModal from "../../Modal/BaseModal";
import {
  FlexContainer,
  IntrviewShowDetail,
  SubmissionStatusContainer,
} from "../../../style/common.style";
import InterviewStatus from "../../CommonComponet/IntwerviewDetail/interviewStatus";
import { SubmissionController } from "../../../controller/SubmissionController";
import { formatName } from "../../../Utils/validation";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../../Utils/CommonString";
import { changeSelectedSubmission } from "../../../feature/store/SubmissionReduxStore";
import { useDispatch } from "react-redux";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";
import OrderFilter from "../../CommonComponet/OrderFilter";

export default function InterviewTableForDashboard(props) {
  const dispatch = useDispatch();
  const submissionController = new SubmissionController();
  const [submissionData, setSubmissionData] = useState([]);
  const [isopenModal, setisOpenModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState();
  const [selectedOrder, setSelectedOrder] = React.useState("ASC");

  useEffect(() => {
    getsubmissionList();
  }, [props.dummuyState, selectedOrder]);

  async function getAscData(data) {
    if (selectedOrder === "DESC") {
      var AscendingData = await data
        .sort((a, b) => {
          return (
            new Date(a?.submissionV2?.childJob?.due_date) -
            new Date(b?.submissionV2?.childJob?.due_date)
          );
        })
        .reverse();
      return AscendingData;
    }
    var descData = await data.sort((a, b) => {
      return (
        new Date(a?.submissionV2?.childJob?.due_date) -
        new Date(b?.submissionV2?.childJob?.due_date)
      );
    });
    return descData;
  }
  async function getsubmissionList() {
    try {
      var status = {
        status: props.submissionStatus,
      };
      var res = await submissionController.getSubmissionsByStatus(status);
      var data = await getAscData(res);
      if (!onCheckLevelAccessibilityForDashboard()) {
        setSubmissionData(data);
        return;
      }
      var comment = JSON.parse(
        localStorage.getItem(CommonStringForLocalStorage.user)
      );
      var filteredData = data.filter(
        (singleData) => singleData.recruiter._id === comment.user._id
      );
      setSubmissionData(filteredData);
      return;
    } catch (error) {
      console.log(error);
    }
  }
  function onViewSubmission(modalSt, data) {
    setisOpenModal(true);
    setModalStatus(modalSt);
    setselectedData(data);
    dispatch(changeSelectedSubmission(data));
  }
  function getColor(e) {
    var inActiveColor = {
      background: CommonColor.white,
      color: CommonColor.primary_color,
    };
    // if (e.interviewData === undefined) {
    //   return inActiveColor;
    // }
    if (e !== undefined) {
      var interViewDate = moment(e.date).format(DATE_FORMATE);
      var currentDate = moment().format(DATE_FORMATE);
      if (interViewDate === currentDate) {
        var color = {
          background: CommonColor.bg_yellow,
          color: CommonColor.primary_color,
        };
        return color;
      }
    }
    return inActiveColor;
  }
  function onGetManualRefreshWithSubmissionId() {
    getsubmissionList();
    // console.log("i am called with id", wantToRemoveSubmissionId);
    // var newSubmissionData = submissionData.filter(function (item) {
    //   return item._id !== wantToRemoveSubmissionId;
    // });
    // console.log("hello", newSubmissionData);

    // setSubmissionData(newSubmissionData);
  }
  return submissionData.length == 0 ? (
    <></>
  ) : (
    <DashboardTableContainer>
      <CustomTableContainer height={"auto"}>
        <BaseModal
          isLoading={isLoading}
          isBig={modalStatus === INTERVIEW_DETAILS ? false : true}
          open={isopenModal}
          formTitle={
            modalStatus === INTERVIEW_DETAILS
              ? INTERVIEW_DETAILS
              : getFormTitleForSubmission(selectedData)
          }
          data={selectedData}
          isSubmissionView={true}
          submissionList={props.submissionList}
          handleClose={() => setisOpenModal(!isopenModal)}
        >
          {modalStatus === INTERVIEW_DETAILS ? (
            <InterviewStatus
              handleClose={() => setisOpenModal(!isopenModal)}
              isLoading={(e) => setisLoading(e)}
              modalStatus={modalStatus}
              selectedData={selectedData}
            />
          ) : (
            <SubmissionViewDetails
              handleClose={() => {
                onGetManualRefreshWithSubmissionId();
                setisOpenModal(!isopenModal);
              }}
              onClickEdit={(e) => onViewSubmission(ADD_INTERVIEW_DETAIL, e)}
              isLoading={(e) => setisLoading(e)}
              onRefresh={() => props.onRefresh()}
              data={selectedData}
            />
          )}
        </BaseModal>
        <TableName>{formatName(props.submissionStatus)}</TableName>
        <Table>
          <TableTbody>
            <TableRow>
              <TableTh textAlignLeft="left">Name</TableTh>
              <TableTh>Title</TableTh>
              <TableTh>ID</TableTh>
              <TableTh>Partner</TableTh>
              <TableTh>Client</TableTh>
              <TableTh>Recruiter</TableTh>
              <TableTh style={{ whiteSpace: "nowrap" }}>
                <OrderFilter
                  selectedOrder={selectedOrder}
                  getValue={(e) => setSelectedOrder(e)}
                  name="Due Date"
                />
              </TableTh>
              <TableTh style={{ whiteSpace: "nowrap" }}>Updated At</TableTh>
              <TableTh textAlignLeft="left">Status</TableTh>
            </TableRow>
            {submissionData.map((singleData, index) => {
              const { full_name, client, parentJob, partner, recruiter } =
                singleData?.submissionV2;
              return (
                <TableRow
                  // style={{
                  //   background: getColor(singleData).background,
                  //   color: getColor(singleData).color,
                  // }}
                  // isActiveDate={
                  //   getColor(singleData).background === CommonColor.bg_yellow
                  // }
                  key={index}
                  onClick={() =>
                    window.innerWidth < 500
                      ? onViewSubmission(
                          VIEW_SUBMISSION,
                          singleData?.submissionV2
                        )
                      : null
                  }
                  onDoubleClick={() =>
                    onViewSubmission(VIEW_SUBMISSION, singleData?.submissionV2)
                  }
                >
                  <TableTd
                    textAlignLeft="left"
                    ListLength={SUBMISSION_TH.length}
                  >
                    <CustomizedTooltip
                      name={formatName(full_name)}
                      title={formatName(full_name)}
                    />
                  </TableTd>
                  <TableTd
                    style={{ maxWidth: 170 }}
                    ListLength={SUBMISSION_TH.length}
                  >
                    <CustomizedTooltip
                      name={parentJob?.position_title}
                      title={parentJob?.position_title}
                    />
                  </TableTd>
                  <TableTd ListLength={SUBMISSION_TH.length}>
                    {parentJob?.client_position_id === ""
                      ? "NA"
                      : parentJob?.client_position_id}
                  </TableTd>
                  <TableTd ListLength={SUBMISSION_TH.length}>
                    {getClientName(partner?.name)}
                  </TableTd>
                  <TableTd ListLength={SUBMISSION_TH.length}>
                    {getClientName(client?.name)}
                  </TableTd>
                  <TableTd ListLength={SUBMISSION_TH.length}>
                    {recruiter?.name?.split(" ")[0]}
                  </TableTd>
                  <TableTd
                    style={{ width: 90 }}
                    ListLength={SUBMISSION_TH.length}
                  >
                    {singleData?.submissionV2?.childJob?.due_date === null
                      ? CommonString.open
                      : moment(
                          singleData?.submissionV2?.childJob?.due_date
                        ).format(DATE_FORMATE)}
                  </TableTd>
                  <TableTd
                    style={{ width: 90 }}
                    ListLength={SUBMISSION_TH.length}
                  >
                    {moment(singleData?.submissionV2._updated_at).format(
                      DATE_FORMATE
                    )}
                  </TableTd>
                  <TableTd
                    textAlignLeft="left"
                    ListLength={SUBMISSION_TH.length}
                  >
                    {props.submissionStatus === "INTERVIEW" ? (
                      <FlexContainer align={"left"}>
                        <SubmissionStatusContainer>
                          <IntrviewShowDetail
                            onClick={(e) =>
                              onViewSubmission(
                                INTERVIEW_DETAILS,
                                singleData.submissionV2
                              )
                            }
                          >
                            {CommonString.interview_details +
                              " (" +
                              moment(singleData?.date).format(DATE_FORMATE) +
                              " - " +
                              (singleData?.interview_time !== null
                                ? `${convertTo12HourFormat(
                                    singleData?.interview_time
                                  )} ${singleData?.time_zone}`
                                : singleData?.time) +
                              ")"}
                          </IntrviewShowDetail>
                        </SubmissionStatusContainer>
                        {/* <InterviewDetailView
                          onClickInterviewDetail={(e) =>
                            onViewSubmission(INTERVIEW_DETAILS, singleData)
                          }
                          data={singleData}
                        /> */}
                      </FlexContainer>
                    ) : singleData?.submissionV2.status ? (
                      singleData?.submissionV2.status.toLowerCase()
                    ) : (
                      ""
                    )}
                  </TableTd>
                </TableRow>
              );
            })}
          </TableTbody>
        </Table>
        {/* {getVisibility() ? <p>No data</p> : <></>} */}
        {/* <Container
        isLoading={isLoadingForSubmissionList === true}
        isVisible={
          filteredData !== undefined
            ? filteredData.length === 0
            : submissionData.length === 0
        }
      /> */}
      </CustomTableContainer>
    </DashboardTableContainer>
  );
}
