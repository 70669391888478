import React, { useState } from "react";
import {
  IsVisibleContactDetail,
  TextWithCopyIcon,
} from "../../style/applicant.style";
import TextWithIcon from "../CommonComponet/TextWithIcon";
import CustomTooltip from "../CommonComponet/Tooltip";
import { CommonString } from "../../Utils/CommonString";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";

export default function GetTextWithIconAndCopy(data, icon) {
  const [display, setDisplay] = useState(false);
  const handleChildClick = function (e) {
    e.stopPropagation();
  };
  function afterCopyDisplayText() {
    setDisplay(true);
    setTimeout(() => {
      setDisplay(false);
    }, 2000);
  }
  return (
    <IsVisibleContactDetail isVisible={data}>
      <TextWithIcon
        icon={icon}
        text={
          <CustomTooltip
            placement={CommonString.top_placement}
            title={display ? CommonString.copied : CommonString.copy}
            name={
              <TextWithCopyIcon
                email={true}
                onMouseLeave={() => setDisplay(false)}
                onClick={(e) => {
                  navigator.clipboard.writeText(data);
                  afterCopyDisplayText();
                  handleChildClick(e);
                }}
              >
                {data}
                <Icon icon={CommonIcon.copy} />
              </TextWithCopyIcon>
            }
          />
        }
      />
    </IsVisibleContactDetail>
  );
}
