import React, { useEffect } from "react";
import VerticalMenu from "../../CommonComponet/VerticalMenu";
import { Job_th_list } from "../../../Utils/constant";
import { UPDATE_JOBS, VIEW_JOBS } from "../../../Utils/enum";
import {
  getAccessForJob,
  getClientName,
  jobStatusColor,
} from "../../../Utils/utilitiFunction";
import { useSelector } from "react-redux";
import {
  CustomTableContainer,
  TableTh,
  TableTd,
  Table,
  TableRow,
  TableTbody,
  JobFirstColumn,
} from "../../../style/table.styled";
import SearchLoader from "../../CommonComponet/SearchLoader";
import {
  CopyTextMainContainer,
  TableNoDataContainer,
} from "../../../style/common.style";
import {
  ButtonForChangePage,
  ButtonForChangePageContainer,
} from "../../../style/button.style";
import { ButtonName } from "../../../Utils/CommonString";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";
import CopyTextComponent from "../../CommonComponet/CopyTextComponent";
import JobCustomController from "../../../controller/JobCustomController";
import NoData from "../../../assets/images/nodata.png";
import RowWithCopyButton from "../../CommonComponet/RowWithCopyButton";
import { MultipleSignContainer } from "../../../style/job.style";

export default function JobTable(props) {
  const {
    onViewModal,
    getDate,
    isVisible,
    // checkBlankArrayForSingleJob,
    // getIndividualJobList,
    onCheckVisibilityOfLoadMoreButton,
    getVerticalMenuVisibility,
    is_loading,
    onDelete,
    getData,
    jobPageNumber,
    getJobListByTabName,
    initial_loader,
    getRecruiterName,
  } = JobCustomController();

  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <>
      <CustomTableContainer height={"80vh"}>
        <Table style={{ zIndex: 1, background: "white" }}>
          <TableTbody>
            <TableRow>
              {Job_th_list.map((data, index) => (
                <TableTh key={index}>{data}</TableTh>
              ))}
              <TableTh isVisible={!getVerticalMenuVisibility()}></TableTh>
            </TableRow>
            {getJobListByTabName(props.is_multiple)?.map((data, index) => {
              // if (!props.is_multiple && data._is_multiple) return;
              return (
                <TableRow
                  key={index + 1}
                  onDoubleClick={() => onViewModal(data, VIEW_JOBS)}
                  onClick={() =>
                    window.innerWidth < 500
                      ? onViewModal(data, VIEW_JOBS)
                      : null
                  }
                >
                  <TableTd isVisible={isVisible("status") || props.isDashboard}>
                    <JobFirstColumn
                      style={{
                        color: jobStatusColor[data.status]?.color,
                        background:
                          jobStatusColor[data.status]?.backgroundColor,
                      }}
                    >
                      {data.status}
                    </JobFirstColumn>
                  </TableTd>
                  <TableTd isVisible={isVisible("due_date")}>
                    {getDate(data)}
                  </TableTd>
                  <TableTd maxHeight={true} isVisible={isVisible("job_title")}>
                    <RowWithCopyButton text={data.job_title} />
                  </TableTd>
                  <TableTd isVisible={isVisible("job_id")}>
                    {data.client_position_id === "" ? (
                      "NA"
                    ) : (
                      <RowWithCopyButton text={data.client_position_id} />
                    )}
                  </TableTd>
                  <TableTd isVisible={isVisible("client")}>
                    <CustomizedTooltip
                      name={getClientName(data.client_name)}
                      title={getClientName(data.client_name)}
                    />
                  </TableTd>
                  <TableTd isVisible={isVisible("max_sub")} isCenter={true}>
                    {data.max_submission === "" ? "NA" : data.max_submission}
                  </TableTd>
                  <TableTd isVisible={isVisible("sub_made")} isCenter={true}>
                    {data.no_of_submission}
                  </TableTd>
                  <TableTd isVisible={isVisible("partner")}>
                    <CustomizedTooltip
                      name={getClientName(data.partner_name)}
                      title={getClientName(data.partner_name)}
                    />
                  </TableTd>
                  <TableTd isVisible={isVisible("recruiter")}>
                    <MultipleSignContainer>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getRecruiterName(data),
                        }}
                      />
                    </MultipleSignContainer>
                  </TableTd>
                  <TableTd
                    isVisible={
                      props.isDashboard || !getVerticalMenuVisibility()
                    }
                    onClick={(event) => event.stopPropagation()}
                  >
                    {getVerticalMenuVisibility() ? (
                      <VerticalMenu
                        data={getAccessForJob(access_data_by_user_id)}
                        onClone={() => onViewModal(data, "Clone Job")}
                        onEdit={() => onViewModal(data, UPDATE_JOBS)}
                        onDelete={() => onDelete(data)}
                      />
                    ) : (
                      ""
                    )}
                  </TableTd>
                </TableRow>
              );
            })}
          </TableTbody>
        </Table>
        <TableNoDataContainer
          isVisible={
            getJobListByTabName(props.is_multiple).length === 0 &&
            !initial_loader
          }
        >
          <img src={NoData} alt="" />
        </TableNoDataContainer>
        <ButtonForChangePageContainer
          isVisible={!onCheckVisibilityOfLoadMoreButton() && !props.is_multiple}
          style={{ marginTop: 5 }}
        >
          <ButtonForChangePage onClick={() => getData(jobPageNumber + 1)}>
            {ButtonName.load_more}
          </ButtonForChangePage>
        </ButtonForChangePageContainer>
      </CustomTableContainer>
      <SearchLoader
        isVisible={
          !is_loading && getJobListByTabName(props.is_multiple).length === 0
        }
      />
    </>
  );
}
