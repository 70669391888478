import React from "react";
import CustomizedTooltip from "./CustomizedTooltip";
import { Icon, Popup } from "semantic-ui-react";
import { CopyTextContainer } from "../../style/job.style";

export default function RowWithCopyButton({ text }) {
  return (
    <CopyTextContainer>
      <CustomizedTooltip name={text} title={text} />
      <div style={{ width: 24 }}>
        <Popup
          trigger={
            <Icon
              name="copy outline"
              style={{ cursor: "pointer" }}
              onClick={() => navigator.clipboard.writeText(text)}
            />
          }
          content={"Copied!"} // Tooltip content
          on="click" // Tooltip appears on click
          style={{
            fontSize: "12px", // Make text smaller
          }}
        />
      </div>
    </CopyTextContainer>
  );
}
